import React, { useEffect, useState } from 'react';
import { useTable } from "react-table";
import { Button1, Button3 } from '../../../../CommonComponents/Button';
import DateRangePicker from '../../../../CommonComponents/DateRangePicker';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineDateRange } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import AddOrganization from './AddOrganization';
import { addOrganization, updateOrganization } from '../../../../../Reducer/EntityReducer';
import UpdateOrganization from './UpdateOrganization';
import Loader from '../../../../CommonComponents/Loader';
import { fetchOrganizationData } from '../../../../../Services/Borrower';
import { organizationPagination } from '../../../../../Services/Pagination';
import DataTable from '../../../../CommonComponents/DataTable';
import Pagination from '../../../../CommonComponents/Pagination';

const BorrowerOrg = () => {

    const dispatch = useDispatch();
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(() => {
        const defaultEndDate = new Date();
        defaultEndDate.setDate(defaultEndDate.getDate());
        return defaultEndDate;
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState(null);
    const [userId, setUserId] = useState();
    const [users, setUsers] = useState([])
    const [error, setError] = useState('');

    const today = new Date();

    const [formData, setFormData] = useState(
        {
            name: '',
            type: '',
            gstNumber: '',
            email: '',
            phone: '',
            panNumber: '',
            authorized_person: '',
            designation: '',
            office_address: '',
            country_name: '',
            state_name: '',
            city_name: '',
            registered_address: '',
            alternate_number: '',
            alternate_email: ''
        }
    )

    const fetchData = async () => {
        setError(null);
        setFetchLoading(true);
        try {
            const data = await fetchOrganizationData(searchQuery);
            setUsers(data.docs);
            console.log("Entity Data is here", data);
            setPagination({
                totalPages: data.totalPages,
                page: data.page,
                hasNextPage: data.hasNextPage,
                hasPrevPage: data.hasPrevPage
            });
            setIsLoading(false);
            dispatch({ type: 'USER', payload: data });
            setFetchLoading(false);
        } catch (error) {
            setIsLoading(false);
            setFetchLoading(false);
        }
    };

    // Pagination functions
    const goToNextPage = async () => {
        const nextPageIndex = pagination.page + 1;
        if (nextPageIndex <= pagination.totalPages) {
            try {
                const data = await organizationPagination(nextPageIndex);
                setUsers(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: nextPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const goToPrevPage = async () => {
        const prevPageIndex = pagination.page - 1;
        if (prevPageIndex >= 1) {
            try {
                const data = await organizationPagination(prevPageIndex);
                setUsers(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: prevPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchQuery]);

    const selectStartDate = (date) => {
        setRangeStart(date);
        setRangeEnd(date);
    };

    const selectEndDate = (date) => {
        setRangeEnd(date);
    };

    const columns = React.useMemo(
        () => [{
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'UCID',
            accessor: 'system_id'
        },
        {
            Header: 'GST No.',
            accessor: row => row.personal && row.personal.gst_number
        },
        {
            Header: 'PAN No.',
            accessor: row => row.personal && row.personal.pancard
        },
        {
            Header: 'Linked Loan',
            accessor: 'linkedLoans',
            Header: 'Linked Loan',
            accessor: 'linkedLoans',
            Cell: ({ row }) => (
                <div className="flex flex-col items-center">
                    {row.original.loans ? row.original.loans.map((loan, index) => (
                        <span key={index}>{loan.identity_number}</span>
                    )) : null}
                </div>
            )
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div className="flex my-2">
                    <Button3
                        label="Edit"
                        onClick={() => handleEdit(row.original._id)}
                    />
                </div>
            )
        },
        ],
        []);

    const data = React.useMemo(() => users);

    console.log("Print Organization Data => ", data);

    const handleSubmit = (values) => {
        dispatch(addOrganization(values));
        setPreviewOpen(false);
        setFetchLoading(true);
        setTimeout(() => {
            fetchData();
        }, 2000)
    };

    const handleAddUser = () => {
        setPreviewOpen(true);
    }

    const handleClose = () => {
        setPreviewOpen(false);
    }

    const handleEdit = (_id) => {
        console.log("Edit Action triggerd");
        setIsUpdateOpen(true);
        setUserId(_id);
    }

    const handleUpdateClose = () => {
        setIsUpdateOpen(false);
    }

    const handleUpdate = (values) => {
        dispatch(updateOrganization({ formData: values, userId: userId }));
        handleUpdateClose();
        setFetchLoading(true);
        setTimeout(() => {
            fetchData();
        }, 500)
    }



    if (isLoading) {
        return <Loader />
    }

    return (
        <>

            <div className=" flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Borrower Details (Organization)</h1>
                    </div>
                    <div className='flex gap-5 mt-5'>
                        <div className='flex mt-5'>
                            <Button1 label="+Add Entity" onClick={handleAddUser} />
                        </div>
                        <div className='flex items-center ml-20'>
                            <div>
                                <DateRangePicker
                                    // selectedDate={rangeStart}
                                    onChange={selectStartDate}
                                    selectsStart
                                    startDate={rangeStart}
                                    endDate={today}
                                    maxDate={today}
                                    placeholder="DD/MM/YYYY"
                                />
                            </div>
                            <div className='date-icon'>
                                <MdOutlineDateRange size={28} />
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div>
                                <DateRangePicker
                                    // selectedDate={rangeEnd}
                                    onChange={selectEndDate}
                                    startDate={rangeStart}
                                    endDate={rangeEnd}
                                    minDate={rangeStart}
                                    maxDate={today}
                                    selectsEnd
                                    placeholder="DD/MM/YYYY"
                                />
                            </div>
                            <div className='date-icon'>
                                <MdOutlineDateRange size={28} />
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        <input
                            type="text"
                            placeholder="Search by Name/Email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-bgclr3 rounded-md w-[875px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
                        />
                    </div>
                    {!error ? (
                        <div>
                            {fetchLoading ? (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="w-[975px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                    <Pagination
                                        hasPrevPage={pagination?.hasPrevPage}
                                        hasNextPage={pagination?.hasNextPage}
                                        goToPrevPage={goToPrevPage}
                                        goToNextPage={goToNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex ml-80 mt-5'>
                            <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
                        </div>
                    )}

                </div>
            </div>

            {isPreviewOpen && (
                <AddOrganization
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}

            {isUpdateOpen && (
                <UpdateOrganization
                    isUpdateOpen={isUpdateOpen}
                    onClose={() => setIsUpdateOpen(false)}
                    handleClose={handleUpdateClose}
                    handleUpdate={handleUpdate}
                    formData={formData}
                    userId={userId}
                />
            )}


        </>
    )
}

export default BorrowerOrg