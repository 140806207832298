import React, { useEffect, useState } from 'react';
import DateRangePicker from '../../../CommonComponents/DateRangePicker';
import { MdOutlineDateRange } from 'react-icons/md';
import Loader from '../../../CommonComponents/Loader';
import DataTable from '../../../CommonComponents/DataTable';
import Pagination from '../../../CommonComponents/Pagination';
import { getPaymentList } from '../../../../Services/Payment';
import { showFailed, showWarning } from '../../../CommonComponents/SweetAlert';
import { IoEyeSharp } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import PaymentDetails from './PaymentDetails';
import * as XLSX from 'xlsx';
import { FaDownload } from 'react-icons/fa';

const PayList = () => {
    const [paymentData, setPaymentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(() => {
        const defaultEndDate = new Date();
        defaultEndDate.setDate(defaultEndDate.getDate());
        return defaultEndDate;
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState(null);
    const [error, setError] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [payment_id, setPayment_id] = useState('');

    const fetchData = async () => {
        try {
            const data = await getPaymentList();
            setPaymentData(data.docs);
            setIsLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                showFailed('Failed...', `${error.response.data.error[0]}`);
                console.log("Bad Request Error: ", error.response.data);
            } else {
                showFailed('Failed...', `${error.response.data.error[0]}`);
                console.error('Error fetching data:', error);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchQuery]);

    const selectStartDate = (date) => {
        setRangeStart(date);
        setRangeEnd(date);
    };

    const selectEndDate = (date) => {
        setRangeEnd(date);
    };

    const openInNewTab = (url) => {
        if (url) {
            window.open(url, '_blank');
        } else {
            showWarning('Not Found', 'Invoice not found.');
        }
    };

    const viewReport = (id) => {
        console.log("Pay ID: ", id);
        setShowDetails(true)
        setPayment_id(id);
    }

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(paymentData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "PaymentData");
        XLSX.writeFile(wb, "PaymentData.xlsx");
    };


    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: row => `${`${row?.first_name || ''} ${row?.last_name || ''}` || `${row?.name}`}`
            },
            {
                Header: 'CUID',
                accessor: 'system_id'
            },
            {
                Header: 'Phone Number',
                accessor: 'phone_number'
            },
            {
                Header: 'Status',
                accessor: 'responseCode'
            },
            {
                Header: 'Invoice Number',
                accessor: 'invoice_number'
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="flex my-2 gap-2 justify-center">
                        <button onClick={() => openInNewTab(row?.original?.user?.invoice[0].url)} className='border-2 border-bgclr rounded-md p-1'><FiDownload className='text-bgclr' size={22} /></button>
                        <button onClick={() => viewReport(row?.original?._id)} className='border-2 border-bgclr rounded-md p-1'><IoEyeSharp className='text-bgclr' size={22} /></button>
                    </div>
                )
            }
        ],
        []
    );

    const data = React.useMemo(() => paymentData, [paymentData]);

    const onClose = () => {
        setShowDetails(false);
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="flex justify-end bg-bgclr4 min-h-screen py-10">
            {!showDetails ? (
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Payment Transaction</h1>
                    </div>
                    <div className='flex gap-5 mt-1'>
                        <div>
                            <h3 className='mt-5 text-[14px] font-[500] text-[#666666]'>Payment Date</h3>
                            <div className='flex'>
                                <div className='flex items-center'>
                                    <DateRangePicker
                                        onChange={selectStartDate}
                                        selectsStart
                                        startDate={rangeStart}
                                        endDate={new Date()}
                                        maxDate={new Date()}
                                        placeholder="DD/MM/YYYY"
                                    />
                                    <MdOutlineDateRange size={28} className='date-icon' />
                                </div>
                                <h3 className='flex items-center px-3 text-[14px] font-[500] text-[#666666]'>to</h3>
                                <div className='flex items-center'>
                                    <DateRangePicker
                                        onChange={selectEndDate}
                                        startDate={rangeStart}
                                        endDate={rangeEnd}
                                        minDate={rangeStart}
                                        maxDate={new Date()}
                                        selectsEnd
                                        placeholder="DD/MM/YYYY"
                                    />
                                    <MdOutlineDateRange size={28} className='date-icon' />
                                </div>
                            </div>
                        </div>
                        <div className='w-[443px]'>
                            <h3 className='mt-5 text-[14px] font-[500] text-[#666666]'>Select Status</h3>
                            <div className='flex justify-between'>
                                <select
                                    className="h-[40px] w-[171px] rounded-[8px] border px-2 border-[#EBEBEB] bg-bgclr5"
                                >
                                    <option value="all">All</option>
                                    <option value="completed">Completed</option>
                                    <option value="failed">Failed</option>
                                    <option value="pending">Pending</option>
                                </select>
                                <div className='flex items-center justify-center'>
                                    <button
                                        className="flex gap-2 items-center text-txt-lt-clr border border-gray-400 px-3 py-1.5 rounded-md bg-bgclr hover:bg-bgclr2"
                                        onClick={exportToExcel}><FaDownload size={18} />Export to Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        <input
                            type="text"
                            placeholder="Search by Name/Email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-bgclr3 rounded-md w-[890px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
                        />
                    </div>
                    {!error ? (
                        <div>
                            {fetchLoading ? (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="w-[920px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                    <Pagination
                                    // hasPrevPage={pagination.hasPrevPage}
                                    // hasNextPage={pagination.hasNextPage}
                                    // goToPrevPage={goToPrevPage}
                                    // goToNextPage={goToNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex ml-80 mt-5'>
                            <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
                        </div>
                    )}
                </div>
            ) : (
                <div className="rounded-md w-3/4 ml-32">
                    <PaymentDetails payment_id={payment_id} onClose={onClose} />
                </div>
            )}
        </div>
    );
};

export default PayList;
