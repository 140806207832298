import { createSlice } from "@reduxjs/toolkit";
import { addBankData, updateBankData } from "../Services/BankOnboarding";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "BANK") {
    return action.payload;
  }
  return state;
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    addBankReducer: (state, action) => {
      addBankData(action.payload);
      console.log("Data : ", action.payload);
    },
    updateBankReducer: (state, action) => {
      const { formData, bankId } = action.payload;
      updateBankData(formData, bankId);
    },
  }
});



export const { addBankReducer, updateBankReducer } = bankSlice.actions;
export default bankSlice.reducer;