import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup';
import * as Yup from "yup";
import { FaSpinner } from 'react-icons/fa';
import { getRoleById } from '../../../../Services/RoleManagement';
import { Loader } from '../../Elements/UpdateLoader';

const RoleActions = ({ isPreviewOpen, name, userId, operation, handleClose, handleSubmit, handleUpdate, isLoadingUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedRole, setUpdatedRole] = useState();

    useEffect(() => {
        if (operation === 'update') {
            // get User by ID
            const getRole = async () => {
                setIsLoading(true);
                try {
                    const data = await getRoleById(userId);
                    console.log("Role by Id : ", data);
                    setUpdatedRole(data.name);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    throw new Error('Error retrieving user data:', error);
                }
            };
            getRole();
            setIsEditing(true);
        }
    }, [operation]);

    const validationSchema = Yup.object({
        name: Yup.string().required('Role Name is Required').min(3, 'Must be at least 3 characters'),
    });


    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50 h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-[500px] mx-auto">
                                    <Formik
                                        initialValues={{
                                            name: isEditing ? updatedRole : name,
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={isEditing ? (values) => handleUpdate(values) : (values) => handleSubmit(values)}
                                    >

                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 py-10 rounded-lg mt-20 text-tbl-txt-clr">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="submit"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedRole(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-xl font-semibold pb-6">
                                                        {isEditing
                                                            ? "Update Role"
                                                            : "Add Role"}
                                                    </h2>
                                                    <div className="mb-2">
                                                        <label htmlFor="name" className="block text-sm font-medium" >
                                                            Role<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Enter Role "
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className="flex justify-center ">
                                                        <button
                                                            type="submit"
                                                            className="px-12 mt-8 py-2 text-txt-lt-clr bg-bgclr2 rounded-full font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                isEditing ? 'Update' : 'Add'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>
        </>
    )
}

export default RoleActions