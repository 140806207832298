import { createContext, useReducer } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Login } from "./Components/Auth/Login";
import { reducer, initialState } from "./Reducer/UserReducer";
import Header from "./Components/CommonComponents/Header";
import Sidebar from "./Components/CommonComponents/Sidebar";
import BorrowerIndividual from "./Components/Dashboard/Screens/Borrowers/Individual/BorrowerIndividualList";
import BorrowerOrg from "./Components/Dashboard/Screens/Borrowers/Organization/BorrowerOrgList";
import PrivateRoute from "./Components/CommonComponents/PrivateRoute";
import Toast from "./Components/CommonComponents/ShowToast";
import UserRegistration from "./Components/Dashboard/Screens/UserManagement/UserRegistration";
import RoleManagement from "./Components/Dashboard/Screens/RoleManagement/RoleManagement";
import CredentialTable from "./Components/Dashboard/Screens/BankCredential/CredentialTable";
import BlogList from "./Components/Dashboard/Screens/BlogManagement/BlogList";
import PayList from "./Components/Dashboard/Screens/PaymentManagement/PayList";
import BankPOD from "./Components/Dashboard/Screens/POD/Pod";
import BankTable from "./Components/Dashboard/Screens/Onboarding/Bank/BankTable";
import BranchTable from "./Components/Dashboard/Screens/Onboarding/Branch/BranchTable";
import LoanMapTable from "./Components/Dashboard/Screens/LoanManagement/LoanMapTable";
import { Toaster } from "react-hot-toast";
import Home from "./Components/Dashboard/Screens/CMSDashboard/Home";
import WhatsappTable from "./Components/Dashboard/Screens/WhatsappCampaign/WhatsappTable";
import EmailTable from "./Components/Dashboard/Screens/EmailCampaign/EmailTable";

export const UserContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Router>
      <UserContext.Provider value={{ state, dispatch }}>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard/*"
            element={
              <>
                <Sidebar />
                <Routes>
                  <Route index element={<Home />} />
                  {/* <Route path="/usermanagement" element={<UserManagement />} /> */}
                  <Route path="/userregistration" element={<UserRegistration />} />
                  <Route path="/onboardingbank" element={<BankTable />} />
                  <Route path="/onboardingbranch" element={<BranchTable />} />
                  <Route path="/bankUser" element={<CredentialTable />} />
                  <Route path="/loanmapping" element={<LoanMapTable />} />
                  <Route path="/borrowerindividual" element={<BorrowerIndividual />} />
                  <Route path="/borrowerorg" element={<BorrowerOrg />} />
                  <Route path="/manageRole" element={<RoleManagement />} />
                  <Route path="/pod" element={<BankPOD />} />
                  <Route path="/whatsappCamp" element={<WhatsappTable />} />
                  <Route path="/emailCamp" element={<EmailTable />} />
                  <Route path="/blogs" element={<BlogList />} />
                  <Route path="/paymentManage" element={<PayList />} />
                </Routes>
              </>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </UserContext.Provider>
      <Toast />
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            marginTop: '70px'
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </Router>
  );
}

export default App;
