import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const getBankRoles = async (bankName) => {
  try {
    const response = await axiosInstance.get(`/bank-roles/?filter_by=bank&value=${bankName}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    throw error;
  }
};


// Add BANK User Data
export const addBankData = async (formData) => {
  console.log("Add Bank User data -> ", formData);
  const updatedData = {
    bank: formData.bankName,
    branch: formData.branchName,
    name: formData.name,
    email: formData.email,
    password: formData.password
  }
  try {
    const response = await axiosInstance.post(`/bank-roles`, updatedData);
    const data = response.data;
    console.log("Bank User response: ", data);

    if (response.status === 200) {
      return data;
    }
    else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  }
  catch (error) {
    console.log("Error Adding CMS User : ", error);
    showFailed('Failed...', `${error.response.data.error[0]}`);
  }
}


// Get Bank User by ID
export const getBankUserById = async (bankId) => {
  try {
    const response = await axiosInstance.get(`/bank-roles/${bankId}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
    throw new Error('Error retrieving user data:', error);
  }
};


// Update CMS User

export const updateBankUserData = async (formData, bankId) => {
  console.log("Updated Bank Name is here: ",formData);  
  const updatedData = {
    bank: formData.bankName,
    branch: formData.branchName,
    name: formData.name,
    email: formData.email,
    status: formData.status
  }
  try {
    const response = await axiosInstance.patch(`/bank-roles/${bankId}`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
    throw new Error('Error retrieving user data:', error);
  }
};

// Delete CMS User

export const deleteCMSUserData = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/roles/user/${userId}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
    throw new Error('Error retrieving user data:', error);
  }
};