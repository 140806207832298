import { createSlice } from "@reduxjs/toolkit";
import { addBranchData, updateBranchData } from "../Services/BranchOnboarding";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "BRANCH") {
    return action.payload;
  }
  return state;
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    addBranchReducer: (state, action) => {
      addBranchData(action.payload);
      console.log("Data : ", action.payload);
    },
    updateBranchReducer: (state, action) => {
      const { formData, branchId } = action.payload;
      updateBranchData(formData, branchId);
    },
  }
});



export const { addBranchReducer, updateBranchReducer } = branchSlice.actions;
export default branchSlice.reducer;