import React, { useEffect, useState } from "react";
import { Popup } from "reactjs-popup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import Cookies from "universal-cookie";
import { getBranchById } from "../../../../../Services/BranchOnboarding";
import { Loader } from "../../../Elements/UpdateLoader";

const BranchOnboarding = ({ handleSubmit, handleUpdate, isBankName, isPreviewOpen, handleClose, isLoadingUpdate, formData, branchId, operation, options }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateUserValue, setUpdatedUserValues] = useState([]);

  useEffect(() => {
    if (operation === 'update') {
      // get User by ID
      const getUserById = async () => {
        setIsLoading(true);
        try {
          const data = await getBranchById(branchId);
          console.log("Role by Id : ", data);
          setUpdatedUserValues({
            bankName: isBankName,
            branchId: data?.b_id,
            ifsc: data.ifsc,
            bankMobile: data?.phone_number,
            authMobileNumber: data?.manager?.phone_number,
            authEmail: data?.manager?.email,
            authDesignation: data?.manager?.designation,
            authName: data?.manager?.name,
            pinCode: data?.address?.pincode,
            state: data?.address?.state,
            city: data?.address?.city,
            address: data?.address?.address,
            gstNumber: data?.personal?.gst_number,
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          throw new Error('Error retrieving user data:', error);
        }
      };
      getUserById();
      setIsEditing(true);
    }
  }, [operation]);

  const validationSchema = Yup.object({
    bankName: Yup.string().required("Required"),
    branchId: Yup.string().required("Required").min(5, "Branch ID must be at least 5 characters").max(7, "Branch ID must be at most 7 characters"),
    ifsc: Yup.string().required("Required").matches(/^[a-zA-Z0-9]{10,13}$/, "Invalid IFSC format"),
    bankMobile: Yup.string().required("Required").matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
    authName: Yup.string().required("Required").min(3, "Name must be at least 3 characters"),
    authDesignation: Yup.string().required("Required").min(2, "Designation must be at least 2 characters"),
    authEmail: Yup.string().email("Invalid email address").required("Required").matches(/@.*\.com$/, "Invalid email format"),
    authMobileNumber: Yup.string().required("Required").matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
    pinCode: Yup.string().required("Required").matches(/^\d{6}$/, "Pin Code must be exactly 6 digits").length(6, "Pin Code must be exactly 6 digits"),
    state: Yup.string().required("Required").min(3, "State must be at least 3 characters"),
    city: Yup.string().required("Required").min(2, "City must be at least 2 characters"),
    address: Yup.string().required("Required"),
    gstNumber: Yup.string().required("Required").matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/, "Invalid GST Number format")
  })


  const fetchPincodeData = async (pincode, setFieldValue) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (!response.status === 200) {
        throw new Error("Failed to fetch pincode data");
      }
      const data = await response.json();

      if (data && data.length > 0) {
        const { PostOffice } = data[0];
        if (PostOffice && PostOffice.length > 0) {
          const { State, District } = PostOffice[0];
          setFieldValue("state", State);
          setFieldValue("city", District);
        }
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };


  return (
    <>
      <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
        {(close) => (
          <div
            className="bg-gray-500 px-28 py-10 mb-20 fixed inset-0 flex bg-opacity-50 h-screen justify-center items-center  overflow-y-scroll"
            style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
          >
            {isLoading ? (
              Loader
            ) : (
              <div className="container p-8 rounded-md mx-auto mt-8 min-h-screen max-h-screen ">
                <div className="mx-28">
                  <Formik
                    initialValues={isEditing ? updateUserValue : formData}
                    validationSchema={validationSchema}
                    onSubmit={isEditing ? (values) => handleUpdate(values) : (values) => handleSubmit(values)}
                  >
                    {(formikProps) => (
                      <Form>
                        <div className="bg-bg-form px-16 pt-10 pb-16 text-tbl-txt-clr">
                          <div className="flex justify-end">
                            <button
                              type="submit"
                              onClick={() => {
                                handleClose();
                                close();
                                // setInitialFormValues(null);
                              }}
                              className="text-2xl font-bold"
                            >
                              X
                            </button>
                          </div>
                          <h2 className="text-xl font-semibold pb-6">
                            {isEditing
                              ? "Edit Branch Details"
                              : "Branch Onboarding"}
                          </h2>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="bankName"
                                className="block text-sm font-medium"
                              >
                                Bank Name<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="select"
                                id="bankName"
                                name="bankName"
                                className="input-style border border-gray-300 rounded-md"
                                value={formikProps?.values?.bankName || isBankName}
                                onChange={(e) => {
                                  formikProps.handleChange(e); // Handle formik change
                                  formikProps.setFieldValue('bankName', e.target.value); // Update gender field value
                              }}
                              >
                                <option value="">Select Bank Name</option>
                                {options?.map((option) => (
                                  <option key={option?._id} value={option?._id}>
                                    {option?.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="bankName"
                                component="div"
                                className="text-red-500 font-semibold"
                              />
                            </div>

                            <div className="mb-4">
                              <label
                                htmlFor="branchId"
                                className="block text-sm font-medium"
                              >
                                Branch Id<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                id="branchId"
                                name="branchId"
                                placeholder="Branch Id"
                                maxLength={7}
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="branchId"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="ifsc"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                IFSC Code<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Ifsc Code"
                                id="ifsc"
                                name="ifsc"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="ifsc"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="branchMobile"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Branch Mobile Number
                                <span className="text-red-800">*</span>
                              </label>
                              <div>
                                <Field
                                  type="text"
                                  placeholder="Branch Mobile"
                                  id="bankMobile"
                                  name="bankMobile"
                                  maxLength={10}
                                  className="input-style border border-gray-300 rounded-md "
                                />
                                <ErrorMessage
                                  name="bankMobile"
                                  component="div"
                                  className="text-red-600 text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          <h2 className="pb-4 block text-md font-medium text-txtclr2">
                            Authorized representative:{" "}
                          </h2>
                          <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                            <div className="mb-4">
                              <label
                                htmlFor="authName"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Name<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Name"
                                id="authName"
                                name="authName"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="authName"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="authDesignation"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Designation<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Designation"
                                id="authDesignation"
                                name="authDesignation"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="authDesignation"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="authEmail"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Email<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Email"
                                id="authEmail"
                                name="authEmail"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="authEmail"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="authMobileNumber"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Mobile Number
                                <span className="text-red-800">*</span>
                              </label>
                              <div>
                                <Field
                                  type="text"
                                  placeholder="Mobile Number"
                                  id="authMobileNumber"
                                  maxLength={10}
                                  name="authMobileNumber"
                                  className="input-style border border-gray-300 rounded-md "
                                />
                                <ErrorMessage
                                  name="authMobileNumber"
                                  component="div"
                                  className="text-red-600 text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <h2 className='pb-4 block text-md font-medium text-txtclr2'>Bank Address: </h2> */}

                          <h2 className="pb-4 block text-md font-medium text-txtclr2">
                            Branch Address:{" "}
                          </h2>
                          <div className="grid md:grid-cols-8 mt-3 md:mt-4 md:gap-5">
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="pinCode"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Pin Code<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="Pin Code"
                                id="pinCode"
                                name="pinCode"
                                maxLength={6}
                                className="input-style border border-gray-300 rounded-md"
                                onBlur={(e) =>
                                  fetchPincodeData(e.target.value, formikProps.setFieldValue)
                                }
                              />
                              <ErrorMessage
                                name="pinCode"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="state"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                State<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="State"
                                id="state"
                                name="state"
                                className="input-style border border-gray-300 rounded-md"
                              />
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-8 mt-3 md:mt-4 md:gap-5">
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                City<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="City"
                                id="city"
                                name="city"
                                className="input-style border border-gray-300 rounded-md"
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="address"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Address<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="Address"
                                id="address"
                                name="address"
                                className="input-style border border-gray-300 rounded-md"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="gstNumber"
                              className="block text-sm font-medium"
                            >
                              Bank GST<span className="text-red-800">*</span>
                            </label>
                            <Field
                              type="text"
                              id="gstNumber"
                              name="gstNumber"
                              placeholder="Bank GST Number"
                              maxLength={15}
                              className="input-style border border-gray-300 rounded-md "
                            />
                            <ErrorMessage
                              name="gstNumber"
                              component="div"
                              className="text-red-600 text-sm"
                            />
                          </div>
                          <div className="flex mt-8 justify-center ">
                            <button
                              type="submit"
                              className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                            >
                              {isLoadingUpdate ? (
                                <FaSpinner className="animate-spin text-3xl" />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default BranchOnboarding;
