import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FaSpinner } from 'react-icons/fa'
import Popup from 'reactjs-popup';
import Select from "react-select";
import { Country, State, City } from "country-state-city";

const AddOrganization = ({ isPreviewOpen, onClose, handleClose, handleSubmit, formData, isLoadingUpdate, setFormData }) => {

    const users = useSelector((state) => state.users);
    console.log("Add Users Data => ", users);
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const indiaOption = Country.getAllCountries().find(country => country.name === "India");

    const ValidationSchema = Yup.object({
        name: Yup.string().required('Entity Name is Required').min(3, 'Must be at least 3 characters'),
        type: Yup.string().required('Organization Type is Required').min(3, 'Must be at least 3 characters'),
        gstNumber: Yup.string().required('GST Number is Required').matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/, 'GST Number is Invalid'),
        email: Yup.string().required('Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        phone: Yup.string().required('Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        panNumber: Yup.string()
            .required('PAN Number is Required')
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'PAN Number is Invalid'),
        authorized_person: Yup.string().required('Authorized Person Name is Required').min(3, 'Must be at least 3 characters'),
        designation: Yup.string().required('Designation is Required').min(3, 'Must be at least 3 characters'),
        state_name: Yup.string().required('State Name is Required'),
        city_name: Yup.string().required('City Name is Required'),
        office_address: Yup.string().required('Office Address is Required').min(5, 'Must be at least 5 characters'),
        registered_address: Yup.string().required('Registered Office Address is Required').min(5, 'Must be at least 5 characters'),
        alternate_number: Yup.string().required('Alternate Number is Required').matches(/^[0-9]{10}$/, 'Alternate Mobile Number is Invalid')
            .test('not-same-as-phone', 'Alternate number cannot be same as mobile number', function (value) {
                return value !== this.parent.phone;
            }),
        alternate_email: Yup.string().required('Alternate Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Alternate Email is Invalid')
            .test('not-same-as-email', 'Alternate email cannot be same as main email', function (value) {
                return value !== this.parent.email;
            })
    })

    const handleStateChange = (selectedState, setValues) => {
        setValues((prevValues) => ({
            ...prevValues,
            state_name: selectedState.name,
            city_name: ''    // Reset city name when state changes
        }));
        setSelectedState(selectedState);
    };

    const handleCityChange = (selectedCity, setValues) => {
        setValues((prevValues) => ({
            ...prevValues,
            city_name: selectedCity.name,
        }));
        setSelectedCity(selectedCity);
    };

    // Organization Type options
    const options = [
        {
            label: 'Association of Persons',
            value: 'association_of_persons'
        },
        {
            label: 'Body of Individuals',
            value: 'body_of_individuals'
        },
        {
            label: 'Company',
            value: 'company'
        },
        {
            label: 'Trust',
            value: 'trust'
        },
        {
            label: 'Limited Liability Partnership',
            value: 'limited_liability_partnership'
        },
        {
            label: 'Firm',
            value: 'firm'
        },
        {
            label: 'Government',
            value: 'government'
        },
        {
            label: 'Hindu Undevided Family',
            value: 'hindu_undevided_family'
        },
        {
            label: 'Artificial Juridical Person',
            value: 'artificial_juridical_person'
        },
        {
            label: 'Local Authority',
            value: 'local_authority'
        }

    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setFilteredOptions(options);
        }
    };

    const handleOptionClick = (option, setFieldValue) => {
        // Handle option selection
        console.log('Selected option:', option);
        setFieldValue('type', option.label);
        setIsOpen(false);
    };

    const handleSameAddress = (formikProps) => {
        formikProps.setFieldValue('registered_address', formikProps.values.office_address);
    };

    return (
        <>

            <Popup open={isPreviewOpen} onClose={onClose} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div className="bg-gray-500 fixed inset-0 flex bg-opacity-50 min-h-screen justify-center items-center overflow-y-scroll" style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none' }}>

                        <div className="container p-8 rounded-md mx-auto  mt-64">
                            <div className='mx-52'>
                                <Formik
                                    initialValues={formData}
                                    validationSchema={ValidationSchema}
                                    onSubmit={(values) => handleSubmit(values)}
                                >
                                    {(formikProps) => (
                                        <Form>
                                            <div className='bg-bg-form px-10 py-10 text-tbl-txt-clr'>
                                                <div className='flex justify-end'>
                                                    <button
                                                        type="submit"
                                                        onClick={() => {
                                                            handleClose();
                                                            close();
                                                        }}
                                                        className="text-2xl font-bold"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                <div className='flex justify-between'>
                                                    <div>
                                                        <h1 className='font-bold text-xl text-gray-500'>Organization Details</h1>
                                                    </div>

                                                    <div>
                                                        <span className='text-red-700 font-semibold text-md'>[* Indicates a required field]</span>
                                                    </div>
                                                </div>
                                                <div className="grid md:grid-cols-2 md:gap-5">
                                                    {/* Entity Name */}
                                                    <div className="mb-4 md:mt-5 mt-8">
                                                        <label htmlFor="name" className="block text-sm font-medium ">
                                                            Entity Name<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            placeholder='Entity Name'
                                                            className="input-style rounded-md"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    {/* Organization Type */}
                                                    <div className="mb-4 md:mt-5">
                                                        <label htmlFor="type" className="block text-sm font-medium text-txtclr2">
                                                            Organization Type<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="type"
                                                            name="type"
                                                            placeholder="Select an option"
                                                            className="dropdown-input input-style"
                                                            onClick={toggleDropdown}
                                                        // onChange={filterOptions} // Add onChange event
                                                        />
                                                        {isOpen && (
                                                            <div className="dropdown-list bg-txt-lt-clr border border-bgclr px-3 py-2 shadow-2xl absolute list-none max-h-[200px] overflow-y-auto">
                                                                {filteredOptions.map((option, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="list-item pb-0.5"
                                                                        onClick={() => handleOptionClick(option, formikProps.setFieldValue)}
                                                                    >
                                                                        {option.label}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <ErrorMessage name="type" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>


                                                <div className="grid md:grid-cols-2 md:gap-5">
                                                    <div className='mb-4'>
                                                        <label htmlFor="gstNumber" className="block text-sm font-medium text-txtclr2">
                                                            GST Number<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" maxLength={15} placeholder='GST Number' id="gstNumber" name="gstNumber" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="gstNumber" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    {/* Email */}
                                                    <div className="mb-4">
                                                        <label htmlFor="email" className="block text-sm font-medium text-txtclr2">
                                                            Email<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" placeholder='Email' id="email" name="email" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="email" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>


                                                <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                                                    {/* Mobile Number */}
                                                    <div className='mb-4'>
                                                        <label htmlFor="phone" className="block text-sm font-medium text-txtclr2">
                                                            Mobile Number<span className='text-red-800'>*</span>
                                                        </label>
                                                        <div className='flex'>
                                                            <Field type="text" maxLength={10} placeholder='Mobile Number' id="phone" name="phone" className="input-style border border-gray-300 rounded-md "
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                }}
                                                            />
                                                        </div>
                                                        <ErrorMessage name="phone" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className='mb-4'>
                                                        <label htmlFor="panNumber" className="block text-sm font-medium text-txtclr2">
                                                            PAN Number<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            maxLength={10}
                                                            placeholder='Pan Number'
                                                            id="panNumber"
                                                            name="panNumber"
                                                            className="input-style border border-gray-300 rounded-md"
                                                            // Update state with the input value
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                formikProps.setFieldValue('panNumber', value.toUpperCase());
                                                            }}
                                                        />
                                                        <ErrorMessage name="panNumber" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>

                                                <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                                                    <div className='mb-4'>
                                                        <label htmlFor="authorized_person" className="block text-sm font-medium text-txtclr2">
                                                            Authorized Person Name<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" placeholder='Authorized Person Name' id="authorized_person" name="authorized_person" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="authorized_person" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className='mb-4'>
                                                        <label htmlFor="designation" className="block text-sm font-medium text-txtclr2">
                                                            Designation<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" placeholder='Designation' id="designation" name="designation" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="designation" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>

                                                <div className="md:grid md:grid-cols-3 md:gap-8">
                                                    <div className="mt-2" disabled>
                                                        <label htmlFor="country_name" className="block text-sm font-medium text-txtclr2">
                                                            Country<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                                }),
                                                            }}
                                                            name='country_name'
                                                            id='country_name'
                                                            options={[{ ...indiaOption, isDisabled: true }]} // Disable India
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.name}
                                                            value={selectedCountry || indiaOption}
                                                            onChange={(item) => setSelectedCountry(item)}
                                                        />
                                                        <ErrorMessage name="country_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="state_name" className="block text-sm font-medium text-txtclr2">
                                                            State<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Select
                                                            name='state_name'
                                                            id='state_name'
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                                }),
                                                            }}
                                                            options={State?.getStatesOfCountry('IN')}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedState}
                                                            // onChange={(item) => {
                                                            //     setSelectedState(item);
                                                            // }}
                                                            onChange={(selectedState) => handleStateChange(selectedState, formikProps.setValues)}
                                                        />
                                                        <ErrorMessage name="state_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="city_name" className="block text-sm font-medium text-txtclr2">
                                                            City<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Select
                                                            name='city_name'
                                                            id='city_name'
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                                }),
                                                            }}
                                                            options={City.getCitiesOfState(
                                                                selectedState?.countryCode,
                                                                selectedState?.isoCode
                                                            )}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedCity}
                                                            onChange={(selectedCity) => handleCityChange(selectedCity, formikProps.setValues)}
                                                        />
                                                        <ErrorMessage name="city_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                </div>


                                                <div className="grid md:grid-cols-9 mt-3 md:mt-4 md:gap-5">
                                                    {/* Current Address */}
                                                    <div className="col-span-4 mb-4">
                                                        <label htmlFor="office_address" className="block text-sm font-medium text-txtclr2">
                                                            Current Office Address<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field as="textarea" placeholder='Current Office Address' id="office_address" name="office_address" className="input-style border border-gray-300 rounded-md"
                                                        />
                                                        <ErrorMessage name="office_address" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className='flex col-span-1 my-2 justify-center'>
                                                        <input type="button" className='w-16 h-9 font-semibold rounded-md my-auto text-txt-lt-clr text-center bg-bgclr2' value='same' onClick={() => handleSameAddress(formikProps)} />
                                                    </div>
                                                    <div className="col-span-4 mb-4">
                                                        <label htmlFor="registered_address" className="block text-sm font-medium text-txtclr2">
                                                            Registered Office Address<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field as="textarea" placeholder='Registered Office Address' id="registered_address" name="registered_address" className="input-style border border-gray-300 rounded-md"
                                                        />
                                                        <ErrorMessage name="registered_address" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>

                                                <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                                                    {/* Alternate number */}
                                                    <div className="mb-4 md:mt-0">
                                                        <label htmlFor="alternate_number" className="block text-sm font-medium text-txtclr2">
                                                            Alternate Number
                                                        </label>
                                                        <Field type="text" maxLength={10}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                            placeholder='Alternate Number' id="alternate_number" name="alternate_number" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="alternate_number" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    {/* Alternate Email */}
                                                    <div className="mb-4 md:mt-0">
                                                        <label htmlFor="alternate_email" className="block text-sm font-medium text-txtclr2">
                                                            Alternate Email
                                                        </label>
                                                        <Field type="text" placeholder='Alternate Email' id="alternate_email" name="alternate_email" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="alternate_email" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>


                                                {/* Submit button */}
                                                <div className="flex mt-4 justify-center ">
                                                    <button
                                                        type="submit"
                                                        className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                    >
                                                        {isLoadingUpdate ? <FaSpinner className="animate-spin text-3xl" /> : 'Submit'}
                                                    </button>
                                                </div>


                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                )}
            </Popup>

        </>
    )
}

export default AddOrganization