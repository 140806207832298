import { createSlice } from "@reduxjs/toolkit";
import { addIndividualData, updateIndividualData } from "../Services/Borrower";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "USER") {
    return action.payload;
  }
  return state;
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addIndividual: (state, action) => {
      addIndividualData(action.payload);
    },
    updateIndividual: (state, action) => {
      const { formData, userId } = action.payload;
      updateIndividualData(formData, userId);
    }
  }
});

export const { addIndividual, updateIndividual } = userSlice.actions;
export default userSlice.reducer;


