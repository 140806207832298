import Swal from "sweetalert2";

export function showSuccessAlert(title, message, timerDuration) {
  let timerInterval;
  Swal.fire({
    position: "top",
    title: title,
    html: message,
    timer: timerDuration,
    timerProgressBar: true,
    didRender: () => {
      const timer = Swal.getPopup().querySelector("b");
      timerInterval = setInterval(() => {
        if (timer) {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    }
  }).then((result) => {
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
    }
  });
}

export function showDeleteAlert(callback) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }
  

  export function showFailed(title, text){
    Swal.fire({
        icon: "error",
        title: title,
        text: text,
      });
}

export function showWarning(title, text){
  Swal.fire({
      icon: "warning",
      title: title,
      text: text,
    });
}
  