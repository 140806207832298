
import React, { useState, useRef, useEffect } from "react";
import { Popup } from "reactjs-popup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSpinner, FaUpload } from "react-icons/fa";
import { Editor } from "@tinymce/tinymce-react";
import { getBlogById, imageCDN } from "../../../../Services/BlogsManagement";

const BlogActions = ({
    isPreviewOpen,
    blogId,
    operation,
    setImgCdn,
    handleClose,
    handleSubmit,
    handleUpdate,
    formData,
    isLoadingUpdate,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserValue, setUpdatedUserValues] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imgLoading, setImgLoading] = useState(false);

    useEffect(() => {
        if (operation === 'update') {
            // get User by ID
            const getBlog = async () => {
                setIsLoading(true);
                try {
                    const data = await getBlogById(blogId);
                    console.log("Role by Id : ", data);
                    setUpdatedUserValues({
                        status: data?.status,
                        title: data?.title,
                        publishDate: data?.publish_date,
                        description: data?.description,
                        pageContent: data?.content,
                        authorName: data?.author,
                        category: data?.category,
                        image: data?.image
                    });
                    setPreview(data?.image);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    throw new Error('Error retrieving user data:', error);
                }
            };
            getBlog();
            setIsEditing(true);
        }
    }, [operation]);

    const handleFileChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = async () => {
                setPreview(reader.result);
                setImgLoading(true);
                try {
                    const uploadResponse = await imageCDN(file);
                    setImgCdn(uploadResponse[0]);
                    setFieldValue("image", uploadResponse[0]);
                } catch (error) {
                    console.error("Error uploading image:", error);
                } finally {
                    setImgLoading(false);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImage = (setFieldValue) => {
        setPreview(null);
        setImgCdn('');
        setFieldValue("image", '');
    }

    const validationSchema = Yup.object({
        status: Yup.string().required("Status is required"),
        title: Yup.string().required("Title is required"),
        publishDate: Yup.date().required("Publish Date is required"),
        description: Yup.string().required("Description is required"),
        authorName: Yup.string().required("Author is required"),
        category: Yup.string().required("Category is required"),
        image: Yup.mixed().required("An image is required"),
    });

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50 h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            <div>
                                <FaSpinner className="animate-spin text-3xl text-gray-50" size={50} />
                            </div>
                        ) : (
                            <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-full mx-44">
                                    <Formik
                                        initialValues={isEditing ? updateUserValue : formData}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) =>
                                            isEditing
                                                ? handleUpdate(values)
                                                : handleSubmit(values)
                                        }
                                    >
                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 mt-[600px] pb-28 pt-12 text-txtclr2">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedUserValues(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-xl font-semibold pb-6">
                                                        {isEditing ? "Update Blog" : "Add Blog"}
                                                    </h2>
                                                    <div className="grid grid-cols-5 mb-6">
                                                        <div className="col-span-1">
                                                            <label
                                                                htmlFor="status"
                                                                className="block text-sm font-medium text-txtclr2"
                                                            >
                                                                Select Status
                                                                <span className="text-red-800">*</span>
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                id="status"
                                                                name="status"
                                                                className="input-style"
                                                            >
                                                                <option value="unpublished">Unpublished</option>
                                                                <option value="published">Published</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="status"
                                                                component="div"
                                                                className="text-red-600 font-semibold"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-6 gap-10">
                                                        <div className="col-span-4 mb-6">
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium"
                                                            >
                                                                Title
                                                                <span className="text-red-600 font-bold">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="title"
                                                                name="title"
                                                                placeholder="Enter Title "
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage
                                                                name="title"
                                                                component="div"
                                                                className="text-red-600 font-semibold"
                                                            />
                                                        </div>
                                                        <div className="col-span-2 mb-6">
                                                            <label
                                                                htmlFor="publishDate"
                                                                className="block text-sm font-medium"
                                                            >
                                                                Publish Date
                                                                <span className="text-red-600 font-bold">*</span>
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                id="publishDate"
                                                                name="publishDate"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage
                                                                name="publishDate"
                                                                component="div"
                                                                className="text-red-600 font-semibold"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-6">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-txtclr2"
                                                        >
                                                            Description
                                                            <span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            placeholder="Enter Description"
                                                            id="description"
                                                            name="description"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage
                                                            name="description"
                                                            component="div"
                                                            className="text-red-600 font-semibold"
                                                        />
                                                    </div>
                                                    <div className="mb-6">
                                                        <label
                                                            htmlFor="page-content"
                                                            className="block text-sm font-medium text-txtclr2"
                                                        >
                                                            Page Content
                                                            <span className="text-red-800">*</span>
                                                        </label>
                                                        <Editor
                                                            apiKey="5dlnsrq6tzt4gya7wkbhryd2slicdwiemrtrvn2gblj40kww"
                                                            onEditorChange={(content) => {
                                                                formikProps.setFieldValue("pageContent", content);
                                                            }}
                                                            value={formikProps.values.pageContent}
                                                        />
                                                        <ErrorMessage
                                                            name="pageContent"
                                                            component="div"
                                                            className="text-red-600 font-semibold"
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-8 gap-5 h-32">
                                                        <div className="col-span-2 mb-6">
                                                            <label
                                                                htmlFor="authorName"
                                                                className="block text-sm font-medium text-txtclr2"
                                                            >
                                                                Author Name
                                                                <span className="text-red-600 font-bold">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                placeholder="Author Name"
                                                                id="authorName"
                                                                name="authorName"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage
                                                                name="authorName"
                                                                component="div"
                                                                className="text-red-600 font-semibold"
                                                            />
                                                        </div>
                                                        <div className="col-span-2 mb-6">
                                                            <label
                                                                htmlFor="category"
                                                                className="block text-sm font-medium text-txtclr2"
                                                            >
                                                                Category
                                                                <span className="text-red-800">*</span>
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                id="category"
                                                                name="category"
                                                                className="input-style"
                                                            >
                                                                <option value="">Select Role</option>
                                                                <option value="banking">Banking</option>
                                                                <option value="legal">Legal</option>
                                                                <option value="document">Document</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="category"
                                                                component="div"
                                                                className="text-red-600 font-semibold"
                                                            />
                                                        </div>
                                                        <div className="col-span-2 mb-6">
                                                            <label
                                                                htmlFor="image"
                                                                className="block text-sm font-medium text-txtclr2"
                                                            >
                                                                Upload Image
                                                                <span className="text-red-800">*</span>
                                                            </label>
                                                            <div className="relative mt-1.5">
                                                                <input
                                                                    id="image"
                                                                    name="image"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={(event) => handleFileChange(event, formikProps.setFieldValue)}
                                                                    className="hidden"
                                                                    disabled={imgLoading || preview}
                                                                />
                                                                <label
                                                                    htmlFor="image"
                                                                    className={`font-bold focus:outline-none focus:ring cursor-pointer flex items-center w-16 justify-center h-10 rounded-md ${imgLoading || preview ? ` hover:bg-gray-600 focus:ring-indigo-200 bg-gray-600 text-gray-400` : `hover:bg-bgclr focus:ring-indigo-200 bg-bgclr2 text-txt-lt-clr`}`}
                                                                    disabled={imgLoading || preview}
                                                                >
                                                                    <FaUpload />
                                                                </label>
                                                            </div>
                                                            <ErrorMessage
                                                                name="image"
                                                                component="div"
                                                                className="text-red-600 font-semibold"
                                                            />
                                                        </div>
                                                        <div className="col-span-2 flex justify-start items-center mb-6">
                                                            {imgLoading ? (
                                                                <div>
                                                                    <FaSpinner className="flex justify-center animate-spin text-3xl" />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {preview && (
                                                                        <div className="mb-6">
                                                                            <label
                                                                                htmlFor="image-preview"
                                                                                className="block text-sm font-medium text-txtclr2"
                                                                            >
                                                                                Image Preview
                                                                            </label>
                                                                            <div className="flex">
                                                                                <img
                                                                                    src={preview}
                                                                                    alt="Selected"
                                                                                    id="image-preview"
                                                                                    style={{ width: "100px", height: "100px", border: "1px solid gray" }}
                                                                                />
                                                                                <div className="flex justify-center items-start ml-3 font-bold">
                                                                                    <button onClick={() => handleImage(formikProps.setFieldValue)} className="border text-[12px] px-1.5 border-red-500 text-red-500">
                                                                                        X
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <button
                                                            type="submit"
                                                            className="w-full py-2 text-txt-lt-clr bg-bgclr2 rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>

                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>
        </>
    );
};

export default BlogActions;
