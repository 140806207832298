import { useNavigate } from "react-router-dom";
import { showFailed } from "./SweetAlert";

export const useDecodeJwtToken = () => {
    const navigate = useNavigate();

    const decodeJwtToken = (token) => {
        if (!token) {
            showFailed('Failed...', 'You are not Authorized.');
            navigate('/');
        } else {
            const base64Url = token.split('.')[1];
            if (!base64Url) {
                throw new Error("Invalid token format");
            }

            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );

            const decodedToken = JSON.parse(jsonPayload);

            return decodedToken;
        }

    };

    return { decodeJwtToken };
};
