import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const fetchRole = async (searchQuery) => {
    try {
        const response = await axiosInstance.get(`/roles?search=${searchQuery}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    }
    catch (error) {
        console.error("Error Retrieving CMS User Data", error);
        throw error; // Re-throw the error to be caught by the component
    }
}

// Add CMS User Data
export const addRole = async (formData) => {
    const updatedData = {
        name: formData.name,
    }
    try {
        const response = await axiosInstance.post(`/roles`, updatedData);
        const data = response.data;
        console.log("CMS User response: ", data);

        if (response.status === 200) {
            return data;
        }
        else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    }
    catch (error) {
        console.log("Error Adding CMS User : ", error);
        showFailed('Failed...',`${error.response.data.error[0]}`);
    }
}


// Get CMS User by ID

export const getRoleById = async (userId) => {
    try {
        const response = await axiosInstance.get(`/roles/${userId}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};


// Update CMS User

export const updateRole = async (formData, userId) => {
    const updatedData = {
        name: formData.name,
    }

    try {
        const response = await axiosInstance.patch(`/roles/${userId}`, updatedData);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
    }
};

// Delete CMS User

export const deleteRole = async (userId) => {
    try {
        const response = await axiosInstance.delete(`/roles/${userId}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};