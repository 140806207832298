import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; 
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getUserRole = () => {
  const token = cookies.get("jwt");
  if (!token) return null;

  try {
    const decoded = jwtDecode(token); 
    return decoded.role; 
  } catch (error) {
    console.error("Error decoding token", error);
    return null;
  }
};

const PrivateRoute = ({ children, allowedRoles }) => {
  const userRole = getUserRole();

  if (!userRole || !allowedRoles.includes(userRole)) {
    // return <Navigate to="/login" replace />;
    return <Navigate to="/pageNotFound" replace />;
  }

  return children;
};

export default PrivateRoute;
