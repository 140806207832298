import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Pagination = ({ hasPrevPage, hasNextPage, goToPrevPage, goToNextPage }) => {
    return (
        <div className='flex mt-6 gap-5 ml-96'>
            <div>
                <button
                    className={`rounded-xl text-gray-200 ${!hasPrevPage ? 'bg-gray-400' : 'bg-gray-700'}`}
                    onClick={goToPrevPage}
                    disabled={!hasPrevPage}
                >
                    <MdKeyboardArrowLeft size={45} />
                </button>
            </div>
            <div>
                <button
                    className={`rounded-xl text-gray-200 ${!hasNextPage ? 'bg-gray-400' : 'bg-gray-700'}`}
                    onClick={goToNextPage}
                    disabled={!hasNextPage}
                >
                    <MdKeyboardArrowRight size={45} />
                </button>
            </div>
        </div>
    );
};

export default Pagination;
