import toast from "react-hot-toast";
import { showToast } from "../Components/CommonComponents/ShowToast";
import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";


export const fetchBranchData = async (searchQuery) => {
  try {
    const response = await axiosInstance.get(`/branch/list?search=${searchQuery}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      throw new Error(`Failed to fetch user data: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error('Error retrieving user data:', error);
  }
};

// Add Bank
export const addBranchData = async (values) => {
  console.log("Updated Data hitted ---> ", values);
  const updatedData = {
    bank: values.bankName,
    b_id: values.branchId,
    ifsc: values.ifsc,
    phone_number: values.bankMobile,
    manager: {
      name: values.authName,
      designation: values.authDesignation,
      email: values.authEmail,
      phone_number: values.authMobileNumber,
    },
    address: {
      pincode: values.pinCode,
      state: values.state,
      city: values.city,
      address: values.address,
    },
    personal: {
      gst_number: values.gstNumber,
    },
  };

  try {
    const response = await axiosInstance.post(`/branch`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      toast.success(`Branch Added Successfully.`);
      return data;
    } else {
      showFailed('Failed...',`${response.statusText}`);
      console.error('Failed to update user data');
    }
  } catch (error) {
    showFailed('Failed...','Not getting API data.')
    console.error('Error occurred while updating user data:', error);
  }

}

// Update Bank
export const updateBranchData = async (values, bankId) => {
  console.log("Branch Update Clicked -- >", values);
  const updatedData = {
    bank: values.bankName,
    b_id: values.branchId,
    ifsc: values.ifsc,
    phone_number: values.bankMobile,
    manager: {
      name: values.authName,
      designation: values.authDesignation,
      email: values.authEmail,
      phone_number: values.authMobileNumber,
    },
    address: {
      pincode: values.pinCode,
      state: values.state,
      city: values.city,
      address: values.address,
    },
    personal: {
      gst_number: values.gstNumber,
    },
  };
  try {
    const response = await axiosInstance.patch(`/branch/${bankId}`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      showToast('User data updated successfully', 'success');
      return data;
    } else {
      showToast(`${response.statusText}`, 'error');
      throw new Error(`Failed to fetch user data: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error('Error retrieving user data:', error);
  }
};

// Get Bank by ID
export const getBranchById = async (branchId) => {
  try {
    const response = await axiosInstance.get(`/branch/single/${branchId}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
    throw new Error('Error retrieving user data:', error);
  }
};

// Get Branch by bank name
export const getBankByName = async (bankName) => {
  try {
    const response = await axiosInstance.get(`/branch/${bankName}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
    throw new Error('Error retrieving user data:', error);
  }
};

// Get Bank Name List
export const getBankList = async () => {
  try {
    const response = await axiosInstance.get(`/bank/search`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
  }
};
