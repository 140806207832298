import toast from "react-hot-toast";
import { showToast } from "../Components/CommonComponents/ShowToast";
import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";
import { camelToSnakeCase } from "../Reducer/EntityReducer";


export const fetchBankData = async (searchQuery) => {
  try {
    const response = await axiosInstance.get(`/bank/list?search=${searchQuery}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed to fetch Bank data', `${response.statusText}`);
    }
  } catch (error) {
    showFailed('Error retrieving user data:', error);
  }
};

// Add Bank
export const addBankData = async (values) => {
  console.log("Updated Data hitted ---> ", values);
  const updatedData = {
    name: values?.bankName,
    bank_id: values?.branchId,
    email: values?.bankEmail,
    phone_number: values?.bankMobile,
    poc: {
      name: values?.name,
      designation: values?.designation,
      email: values?.email,
      phone_number: values?.mobileNumber,
    },
    address: {
      pincode: values?.pinCode,
      state: values?.state,
      city: values?.city,
      address: values?.address,
    },
    personal: {
      gst_number: values?.gstNumber,
      pancard: values?.panCard
    }
  };

  try {
    const response = await axiosInstance.post(`/bank`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      toast.success(`Bank Added Successfully.`);
      return data;
    } else {
      showFailed('Failed...', `${response.statusText}`);
      console.error('Failed to update user data');
    }
  } catch (error) {
    showFailed('Failed...', `${error}`);
    console.error('Error occurred while updating user data:', error);
  }

}

// Update Bank
export const updateBankData = async (values, bankId) => {
  console.log("------------------first")
  const updatedData = {
    name: values?.bankName,
    bank_id: values?.bank_id,
    email: values?.bankEmail,
    phone_number: values?.bankMobile,
    poc: {
      name: values?.name,
      designation: values?.designation,
      email: values?.email,
      phone_number: values?.mobileNumber,
    },
    address: {
      pincode: values?.pinCode,
      state: values?.state,
      city: values?.city,
      address: values?.address,
    },
    personal: {
      gst_number: values?.gstNumber,
      pancard: values?.panCard
    }
  };
  try {
    const response = await axiosInstance.patch(`/bank/${bankId}`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      toast.success(`Bank data updated successfully`);
      return data;
    } else {
      showFailed('Failed..', `${response.statusText}`);
      console.error("Failed to update banks data: ", response.statusText);
    }
  } catch (error) {
    showFailed('Failed...', 'Not getting API data.');
    console.error("API Failed: ", error);
  }
};

// Get Bank by ID
export const getBankById = async (bankId) => {
  try {
    const response = await axiosInstance.get(`/bank/${bankId}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
    throw new Error('Error retrieving user data:', error);
  }
};