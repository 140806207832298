import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateRangePicker({ selectedDate, onChange, minDate, maxDate, selectsStart, selectsEnd, startDate, endDate, placeholder, className }) {
    const [date, setDate] = useState(selectedDate);

    const handleDateChange = (date) => {
        setDate(date);
        onChange(date);
    };

    const handleClearDate = () => {
        setDate(null);
        onChange(null);
    };

    return (
        <>
            <div className="flex relative">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={date}
                    onChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectsStart={selectsStart}
                    selectsEnd={selectsEnd}
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={placeholder}
                />
                {date && (
                    <button
                        onClick={handleClearDate}
                        className="absolute font-bold right-0 top-0 mt-2 text-[9px] mr-[10px] bg-gray-800 text-bg-table-data px-1 rounded-full"
                        // style={{ zIndex: 1 }}
                    >
                        X
                    </button>
                )}
            </div>
        </>
    );
}

export default DateRangePicker;
