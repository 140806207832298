import { createSlice } from "@reduxjs/toolkit";
import { addBlogData, updateBlogData } from "../Services/BlogsManagement";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "BLOGS") {
    return action.payload;
  }
  return state;
};

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    addBlogs: (state, action) => {
      const { formData, imgCdn } = action.payload;
      addBlogData(formData, imgCdn);
      console.log("Blog Data : ", action.payload);
    },
    updateBlogs: (state, action) => {
      const { formData, blogId, imgCdn } = action.payload;
      updateBlogData(formData, blogId, imgCdn);
    }
  }
});



export const { addBlogs, updateBlogs } = blogSlice.actions;
export default blogSlice.reducer;