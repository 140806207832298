import { createSlice } from "@reduxjs/toolkit";
import { addOrganizationData, updateOrganizationData } from "../Services/Borrower";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "ENTITY") {
    return action.payload;
  }

  return state;
};

export function camelToSnakeCase(str) {
  return str.replace(/\s+/g, '_').replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).replace(/^_+|_+$/g, '').replace(/_+/g, '_');
}

const organizationSlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    addOrganization: (state, action) => {
      addOrganizationData(action.payload);
    },
    updateOrganization: (state, action) => {
      const { formData, userId } = action.payload;
      updateOrganizationData(formData, userId);
    }
  }
})

export const { addOrganization, updateOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
