import React, { useState } from 'react'
import Popup from 'reactjs-popup';
import { Loader } from '../../Elements/UpdateLoader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FaSpinner } from 'react-icons/fa';

const ScheduleCamp = ({ isPreviewOpen, onClose, userId, roles, operation, handleClose, handleSubmit, handleUpdate, formData, isLoadingUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserValue, setUpdatedUserValues] = useState([]);

    console.log("first");

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50  h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-[950px] mx-auto">
                                    <Formik
                                        initialValues={isEditing ? updateUserValue : formData}
                                    // validationSchema={validationSchema}
                                    // onSubmit={values => isEditing ? handleUpdate(values, updateUserValue.permission) : handleSubmit(values, updateUserValue.permission)}
                                    >
                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 py-16 text-tbl-txt-clr">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedUserValues(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-xl font-semibold pb-6">
                                                        {isEditing
                                                            ? "Update CMS User"
                                                            : "Add CMS User"}
                                                    </h2>
                                                    <div className="mb-6">
                                                        <label htmlFor="campName" className="block text-sm font-medium" >
                                                            Campaign Name<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="campName"
                                                            name="campName"
                                                            placeholder="Enter Campaign Name"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="campName" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className="mb-6">
                                                        <label htmlFor="campTemplate" className="block text-sm font-medium" >
                                                            Campaign Template<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="campTemplate"
                                                            name="campTemplate"
                                                            placeholder="Enter Campaign Template"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="campTemplate" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className="mb-6">
                                                        <label htmlFor="userType" className="block text-sm font-medium" >
                                                            Choose User<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <div className='flex gap-7 mt-2'>
                                                            <div className="flex gap-2">
                                                                <Field
                                                                    type="radio"
                                                                    id="campTemplate"
                                                                    name="campTemplate"
                                                                    placeholder="Enter Campaign Template"
                                                                    className=""
                                                                />
                                                                <label htmlFor="campTemplate" className="block text-sm font-medium" >
                                                                    Comma Separated Phone no.
                                                                </label>
                                                            </div>
                                                            <div className="flex gap-2">
                                                                <Field
                                                                    type="radio"
                                                                    id="campTemplate"
                                                                    name="campTemplate"
                                                                    placeholder="Enter Campaign Template"
                                                                    className=""
                                                                />
                                                                <label htmlFor="campTemplate" className="block text-sm font-medium" >
                                                                    Pre-defined List
                                                                </label>
                                                            </div>
                                                            <div className="flex gap-2">
                                                                <Field
                                                                    type="radio"
                                                                    id="campTemplate"
                                                                    name="campTemplate"
                                                                    placeholder="Enter Campaign Template"
                                                                    className=""
                                                                />
                                                                <label htmlFor="campTemplate" className="block text-sm font-medium" >
                                                                    Upload CSV
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            type="text"
                                                            id="userType"
                                                            name="userType"
                                                            placeholder="Enter Campaign Template"
                                                            className="input-style mt-2"
                                                        />
                                                        <ErrorMessage name="userType" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className="flex justify-center mt-5">
                                                        <button
                                                            type="submit"
                                                            className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>

        </>
    )
}

export default ScheduleCamp