import { createSlice } from "@reduxjs/toolkit";
import { addCmsUser, deleteCMSUserData, updateCMSUserData } from "../Services/CmsUserManagement";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "CMSUSER") {
    return action.payload;
  }
  return state;
};

const cmsUserSlice = createSlice({
  name: "cmsusers",
  initialState,
  reducers: {
    addCMSUser: (state, action) => {
      const { formData, permissions } = action.payload;
      addCmsUser(formData, permissions);
    },
    updateCMSUser: (state, action) => {
      const { formData, userId, permissions } = action.payload;
      updateCMSUserData(formData, userId, permissions);
    },
    deleteCMSUser: (state, action) => {
      deleteCMSUserData(action.payload);
    }
  }
});



export const { addCMSUser, updateCMSUser, deleteCMSUser } = cmsUserSlice.actions;
export default cmsUserSlice.reducer;