import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const getDashboardData = async () => {
    try {
        const response = await axiosInstance.get(`/dashboard/allBank`);
        const data = response.data;
        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};