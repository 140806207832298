import React, { useState, useEffect } from "react";
import { Popup } from "reactjs-popup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import { getBankById } from "../../../../../Services/BankOnboarding";
import { Loader } from "../../../Elements/UpdateLoader";

const BankOnboarding = ({ handleSubmit, handleUpdate, isPreviewOpen, handleClose, isLoadingUpdate, formData, bankId, operation, }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateUserValue, setUpdatedUserValues] = useState([]);

  useEffect(() => {
    if (operation === 'update') {
      // get User by ID
      const getUserById = async () => {
        setIsLoading(true);
        try {
          const data = await getBankById(bankId);
          console.log("Role by Id : ", data);
          setUpdatedUserValues({
            bankName: data?.name,
            branchId: data?.bank_id,
            bankEmail: data?.email,
            bankMobile: data?.phone_number,
            name: data?.poc?.name,
            designation: data?.poc?.designation,
            email: data?.poc?.email,
            mobileNumber: data?.poc?.phone_number,
            pinCode: data?.address?.pincode,
            state: data?.address?.state,
            city: data?.address?.city,
            address: data?.address?.address,
            panCard: data?.personal?.pancard,
            gstNumber: data?.personal?.gst_number
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          throw new Error('Error retrieving user data:', error);
        }
      };
      getUserById();
      setIsEditing(true);
    }
  }, [operation]);

  const indianMobileRegExp = /^\d{10}$/;

  const fetchPincodeData = async (pincode, setFieldValue) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (!response.status === 200) {
        throw new Error("Failed to fetch pincode data");
      }
      const data = await response.json();
      console.log("Pincode data:", data);

      if (data && data.length > 0) {
        const { PostOffice } = data[0];
        if (PostOffice && PostOffice.length > 0) {
          const { State, District } = PostOffice[0];
          setFieldValue("state", State);
          setFieldValue("city", District);
        }
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };

  const validationSchema = Yup.object({
    bankName: Yup.string().required("Required"),
    branchId: Yup.string().required("Required").min(5, "Branch ID must be at least 5 characters").max(7, "Branch ID must be at most 7 characters"),
    bankEmail: Yup.string().email("Invalid email address").required("Required").matches(/@.*\.com$/, "Invalid email format"),
    bankMobile: Yup.string().required("Required").matches(indianMobileRegExp, "Mobile number must be exactly 10 digits"),
    name: Yup.string().required("Required").min(3, "Name must be at least 3 characters"),
    designation: Yup.string().required("Required").min(2, "Designation must be at least 2 characters"),
    email: Yup.string().email("Invalid email address").required("Required").matches(/@.*\.com$/, "Invalid email format"),
    mobileNumber: Yup.string().required("Required").matches(indianMobileRegExp, "Mobile number must be exactly 10 digits."),
    pinCode: Yup.string().required("Required").matches(/^\d{6}$/, "Pin Code must be exactly 6 digits").length(6, "Pin Code must be exactly 6 digits"),
    state: Yup.string().required("Required").min(3, "State must be at least 3 characters"),
    city: Yup.string().required("Required").min(2, "City must be at least 2 characters"),
    address: Yup.string().required("Required"),
    panCard: Yup.string().required("Required").matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid PAN Card format"),
    gstNumber: Yup.string().required("Required").matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/, "Invalid GST Number format")
  })

  return (
    <>

      <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
        {(close) => (
          <div
            className="bg-gray-500 px-28 py-10 mb-10 fixed inset-0 flex bg-opacity-50 h-screen justify-center items-center  overflow-y-scroll"
            style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
          >
            {isLoading ? (
              Loader
            ) : (
              <div className="container p-8 rounded-md mx-auto mt-8 min-h-screen max-h-screen ">
                <div className="mx-28">
                  <Formik
                    initialValues={isEditing ? updateUserValue : formData} // Use initialFormValues when editing
                    validationSchema={validationSchema}
                    onSubmit={isEditing ? (values) => handleUpdate(values) : (values) => handleSubmit(values)}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form>
                        <div className="bg-bg-form px-16 pt-10 pb-16 text-tbl-txt-clr">
                          <div className="flex justify-end">
                            <button
                              type="submit"
                              onClick={() => {
                                handleClose();
                                close();
                                setUpdatedUserValues(null);
                              }}
                              className="text-2xl font-bold"
                            >
                              X
                            </button>
                          </div>
                          <h2 className="text-xl font-semibold pb-6">
                            {isEditing
                              ? "Edit Bank Details"
                              : "Bank Onboarding"}
                          </h2>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="bankName"
                                className="block text-sm font-medium"
                              >
                                Bank Name<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                id="bankName"
                                name="bankName"
                                placeholder="Bank Name"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="bankName"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="branchId"
                                className="block text-sm font-medium"
                              >
                                Bank Id<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                id="branchId"
                                name="branchId"
                                placeholder="Bank Id"
                                maxLength={7}
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="branchId"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="bankEmail"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Bank Email<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Bank Email"
                                id="bankEmail"
                                name="bankEmail"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="bankEmail"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="bankMobile"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Bank Landline Number
                                <span className="text-red-800">*</span>
                              </label>
                              <div>
                                <Field
                                  type="text"
                                  placeholder="Bank Mobile"
                                  id="bankMobile"
                                  name="bankMobile"
                                  maxLength={10}
                                  className="input-style border border-gray-300 rounded-md "
                                />
                                <ErrorMessage
                                  name="bankMobile"
                                  component="div"
                                  className="text-red-600 text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          <h2 className="pb-4 block text-md font-medium text-txtclr2">
                            Authorized representative:{" "}
                          </h2>
                          <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                            <div className="mb-4">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Name<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Name"
                                id="name"
                                name="name"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="designation"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Designation<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Designation"
                                id="designation"
                                name="designation"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="designation"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Email<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Email"
                                id="email"
                                name="email"
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="mobileNumber"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Mobile Number
                                <span className="text-red-800">*</span>
                              </label>
                              <div>
                                <Field
                                  type="text"
                                  placeholder="Mobile Number"
                                  id="mobileNumber"
                                  maxLength={10}
                                  name="mobileNumber"
                                  className="input-style border border-gray-300 rounded-md "
                                />
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="div"
                                  className="text-red-600 text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          <h2 className="pb-4 block text-md font-medium text-txtclr2">
                            Bank Address:{" "}
                          </h2>
                          <div className="grid md:grid-cols-8 mt-3 md:mt-4 md:gap-5">
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="pinCode"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Pin Code<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="Pin Code"
                                id="pinCode"
                                name="pinCode"
                                maxLength={6}
                                className="input-style border border-gray-300 rounded-md"
                                onBlur={(e) =>
                                  fetchPincodeData(e.target.value, setFieldValue)
                                }
                              />
                              <ErrorMessage
                                name="pinCode"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="state"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                State<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="State"
                                id="state"
                                name="state"
                                className="input-style border border-gray-300 rounded-md"
                              />
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-8 mt-3 md:mt-4 md:gap-5">
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                City<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="City"
                                id="city"
                                name="city"
                                className="input-style border border-gray-300 rounded-md"
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="col-span-4 mb-4">
                              <label
                                htmlFor="address"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Address<span className="text-red-800">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="Address"
                                id="address"
                                name="address"
                                className="input-style border border-gray-300 rounded-md"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="grid md:grid-cols-2 md:gap-5">
                            <div className="mb-4">
                              <label
                                htmlFor="panCard"
                                className="block text-sm font-medium"
                              >
                                Bank Pan Card<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                id="panCard"
                                name="panCard"
                                placeholder="Bank Pan Card"
                                maxLength={10}
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="panCard"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="gstNumber"
                                className="block text-sm font-medium"
                              >
                                Bank GST<span className="text-red-800">*</span>
                              </label>
                              <Field
                                type="text"
                                id="gstNumber"
                                name="gstNumber"
                                placeholder="Bank GST Number"
                                maxLength={15}
                                className="input-style border border-gray-300 rounded-md "
                              />
                              <ErrorMessage
                                name="gstNumber"
                                component="div"
                                className="text-red-600 text-sm"
                              />
                            </div>
                          </div>
                          <div className="flex mt-4 justify-center ">
                            <button
                              type="submit"
                              className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                            >
                              {isLoadingUpdate ? (
                                <FaSpinner className="animate-spin text-3xl" />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default BankOnboarding;
