import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { fetchBankData } from '../../../../../Services/BankOnboarding';
import { useDispatch } from 'react-redux';
import Loader from '../../../../CommonComponents/Loader';
import DateRangePicker from '../../../../CommonComponents/DateRangePicker';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineDateRange } from 'react-icons/md';
import { Button1, Button3 } from '../../../../CommonComponents/Button';
import BankOnboarding from './BankOnboarding';
import { addBankReducer, updateBankReducer } from '../../../../../Reducer/bankReducer';
import { showFailed } from '../../../../CommonComponents/SweetAlert';
import { bankPagination } from '../../../../../Services/Pagination';
import DataTable from '../../../../CommonComponents/DataTable';
import Pagination from '../../../../CommonComponents/Pagination';

const BankTable = () => {

  const [errors, setError] = useState();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [operation, setOperation] = useState('add');
  const [isLoading, setIsLoading] = useState(true);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [rangeStart, setRangeStart] = useState(new Date());
  const [rangeEnd, setRangeEnd] = useState(() => {
    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate());
    return defaultEndDate;
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [pagination, setPagination] = useState(null);
  const [bankId, setBankId] = useState();

  const formData = {
    bankName: "",
    branchId: "",
    bankEmail: "",
    bankMobile: "",
    name: "",
    designation: "",
    email: "",
    mobileNumber: "",
    pinCode: "",
    state: "",
    city: "",
    address: "",
    panCard: "",
    gstNumber: ""
  };

  const fetchBanksData = async () => {
    setError(null);
    setFetchLoading(true);
    try {
      const data = await fetchBankData(searchQuery);
      console.log("Banks Data : ", data.docs)
      setUsers(data.docs);
      setPagination({
        totalPages: data.totalPages,
        page: data.page,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage
      });
      dispatch({ type: 'BANK', payload: data });
      setSubmitLoading(false);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.error[0])
      } else {
        console.error('Error fetching data:', error);
      }
      setSubmitLoading(false);
    }
  }

  const goToNextPage = async () => {
    const nextPageIndex = pagination.page + 1;
    if (nextPageIndex <= pagination.totalPages) {
      try {
        const data = await bankPagination(nextPageIndex);
        setUsers(data.docs);
        setPagination(prevState => ({
          ...prevState,
          page: nextPageIndex,
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const goToPrevPage = async () => {
    const prevPageIndex = pagination.page - 1; // Use pagination state
    if (prevPageIndex >= 1) { // Fix comparison
      try {
        const data = await bankPagination(prevPageIndex); // Pass prevPageIndex
        setUsers(data.docs);
        setPagination(prevState => ({
          ...prevState,
          page: prevPageIndex, // Update page in pagination state
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    fetchBanksData();
  }, [searchQuery]);

  const today = new Date();

  const selectStartDate = (date) => {
    setRangeStart(date);
    setRangeEnd(date);
  };

  const selectEndDate = (date) => {
    setRangeEnd(date);
  };

  const handleEdit = (_id) => {
    console.log("Edit Action triggerd", _id);
    setOperation('update');
    setPreviewOpen(true);
    setBankId(_id)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Bank Name',
        accessor: 'name',
      },
      {
        Header: 'Bank Address',
        accessor: 'address.address',
      },
      {
        Header: 'Manager Name',
        accessor: 'poc.name',
      },
      {
        Header: 'Contact No.',
        accessor: 'phone_number',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <div className="flex my-2">
            <Button3
              label="Edit"
              onClick={() => handleEdit(row.original._id)}
            />
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => users);

  console.log("Print the Data => ", data);

  const handleClose = async () => {
    setPreviewOpen(false);
  }

  const handleAddBank = () => {
    setOperation('Add');
    setPreviewOpen(true);
  }

  const handleSubmit = (values) => {
    try {
      dispatch(addBankReducer(values));
      setPreviewOpen(false);
      setSubmitLoading(true);
      setTimeout(async () => {
        await fetchBanksData();
      }, 2000);
    } catch (error) {
      console.error('Error adding user:', error);
      setSubmitLoading(false);
      showFailed("Failed to add user. Please try again later.");
    }
  };

  const handleUpdate = (values) => {
    dispatch(updateBankReducer({ formData: values, bankId: bankId }));
    handleClose();
    setSubmitLoading(true);
    setTimeout(() => {
      fetchBanksData();
    }, 500)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>

      <div className="flex justify-end bg-gray-200 min-h-screen py-10">
        <div className="rounded-md w-3/4 ml-32">
          <div className="flex">
            <h1 className="text-3xl font-bold">Bank Onboarding</h1>
          </div>
          <div className="flex gap-5 mt-5 justify-center">
            <div className="flex">
              <div className="flex items-center">
                <div>
                  <DateRangePicker
                    onChange={selectStartDate}
                    selectsStart
                    startDate={rangeStart}
                    endDate={today}
                    maxDate={today}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <div className="date-icon">
                  <MdOutlineDateRange size={28} />
                </div>
              </div>
              <div className="flex items-center">
                <div>
                  <DateRangePicker
                    onChange={selectEndDate}
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    minDate={rangeStart}
                    maxDate={today}
                    selectsEnd
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <div className="date-icon">
                  <MdOutlineDateRange size={28} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-[-30px] ml-10">
            <Button1 label="+Add Bank" onClick={handleAddBank} />
          </div>
          <div className='flex ml-9'>
            <input
              type="text"
              placeholder="Search by Name/Email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border border-bgclr3 rounded-md w-[823px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
            />
          </div>
          {!errors ? (
            <div className="w-[975px] mt-6 mb-20">
              {isSubmitLoading ? (
                <div className="-mt-36 -ml-32">
                  <Loader />
                </div>
              ) : (
                <div>
                  <div className="pt-3 overflow-x-auto mb-10">
                    <DataTable
                      columns={columns}
                      data={data}
                    />
                  </div>
                  <Pagination
                    hasPrevPage={pagination.hasPrevPage}
                    hasNextPage={pagination.hasNextPage}
                    goToPrevPage={goToPrevPage}
                    goToNextPage={goToNextPage}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='flex ml-80 mt-5'>
              <h1 className='font-bold text-3xl text-gray-500'>{errors}</h1>
            </div>
          )}
        </div>
      </div>

      {isPreviewOpen && (
        <BankOnboarding
          isPreviewOpen={isPreviewOpen}
          onClose={() => setPreviewOpen(false)}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          formData={formData}
          isLoadingUpdate={isLoadingUpdate}
          bankId={bankId}
          operation={operation}
        />
      )}

    </>
  );
};

export default BankTable;
