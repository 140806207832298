import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

// Borrower organization Pagination
export const individualPagination = async (page) => {
    try {
        const response = await axiosInstance.get(`/user?page=${page}`);

        if (response.status === 200) {
            const data = await response.data;
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};

// Borrower organization Pagination
export const organizationPagination = async (page) => {
    try {
        const response = await axiosInstance.get(`/organization?page=${page}`);

        if (response.status === 200) {
            const data = await response.data;
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};

// CMS user pagination
export const cmsUserPagination = async (page) => {
      try {
        const response = await axiosInstance.get(`/user?page=${page}`);
        const data = response.data;

          if (response.status === 200) {
              return data;
          } else {
              throw new Error(`Failed to fetch user data: ${response.statusText}`);
          }
      } catch (error) {
          throw new Error('Error retrieving user data:', error);
      }
  };

// Bank onboarding Pagination
  export const bankPagination = async (page) => {
    try {
      const response = await axiosInstance.get(`/bank?page=${page}`);
      const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};

// Branch onboarding Pagination
export const branchPagination = async (page) => {
    try {
      const response = await axiosInstance.get(`/branch?page=${page}`);
      const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};

// Bank user pagination
export const bankUserPagination = async (page) => {
    try {
      const response = await axiosInstance.get(`/user?page=${page}`);
      const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};

// Blog Management pagination
export const blogPagination = async (page) => {
    try {
      const response = await axiosInstance.get(`/blogs/list?page=${page}`);
      const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};

//Loan Table Pagination
export const loanPagination = async (page) => {
    try {
      const response = await axiosInstance.get(`/loan?page=${page}`);
      const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
};