import React from 'react'
import Popup from 'reactjs-popup';
import { Loader } from '../../Elements/UpdateLoader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { MdAccessTimeFilled, MdDateRange } from 'react-icons/md';
import { Button1 } from '../../../CommonComponents/Button';

const ScheduleMaintenance = ({ isPreviewOpen, handleClose, handleUpdate, handleSubmit, isLoading, isEditing }) => {
    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50  h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-[656px] mx-auto">
                                    <Formik
                                    // initialValues={isEditing ? updateUserValue : formData}
                                    // validationSchema={validationSchema}
                                    // onSubmit={values => isEditing ? handleUpdate(values, updateUserValue.permission) : handleSubmit(values, updateUserValue.permission)}
                                    >
                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form h-[366px] p-[22px] rounded-[14px] text-tbl-txt-clr">
                                                    <div className="flex justify-between">
                                                        <h2 className="text-xl font-semibold">
                                                            {isEditing
                                                                ? "Update Schedule Maintenance"
                                                                : "Schedule Maintenance"}
                                                        </h2>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                            }}
                                                            className="text-xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <div className="flex w-[50%] gap-20 mt-[30px]">
                                                        <label
                                                            htmlFor="scheduleDate"
                                                            className="flex items-center gap-2 text-[16px] font-medium"
                                                        >
                                                            <MdDateRange size={20} />Date<span className="text-red-800">*</span>
                                                        </label>
                                                        <Field
                                                            type="date"
                                                            id="scheduleDate"
                                                            name="scheduleDate"
                                                            className="w-[141px] h-[28px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                        />
                                                        <ErrorMessage
                                                            name="scheduleDate"
                                                            component="div"
                                                            className="text-red-600 text-sm"
                                                        />
                                                    </div>

                                                    <div className='flex justify-between gap-10 mt-[30px]'>
                                                        <div className="flex w-[50%] gap-4">
                                                            <label
                                                                htmlFor="startTime"
                                                                className="flex items-center gap-1 text-[16px] font-medium"
                                                            >
                                                                <MdAccessTimeFilled size={20} />Schedule Time<span className="text-red-800">*</span>
                                                            </label>
                                                            <Field
                                                                type="time"
                                                                id="startTime"
                                                                name="startTime"
                                                                className="w-[141px] h-[28px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                            />
                                                            <ErrorMessage
                                                                name="startTime"
                                                                component="div"
                                                                className="text-red-600 text-sm"
                                                            />
                                                        </div>
                                                        <div className="flex gap-5">
                                                            <label
                                                                htmlFor="endTime"
                                                                className="flex items-center gap-1 text-[16px] font-medium"
                                                            >
                                                                End Time<span className="text-red-800">*</span>
                                                            </label>
                                                            <Field
                                                                type="time"
                                                                id="endTime"
                                                                name="endTime"
                                                                className="w-[141px] h-[28px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                            />
                                                            <ErrorMessage
                                                                name="endTime"
                                                                component="div"
                                                                className="text-red-600 text-sm"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-between gap-8 mt-[30px]">
                                                        <label
                                                            htmlFor="description"
                                                            className="flex items-start gap-2 text-[16px] font-medium"
                                                        >
                                                            <MdDateRange size={20} />Description<span className="text-red-800">*</span>
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            id="description"
                                                            name="description"
                                                            className="w-[457px] h-[86px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                        />
                                                        <ErrorMessage
                                                            name="description"
                                                            component="div"
                                                            className="text-red-600 text-sm"
                                                        />
                                                    </div>

                                                    <div className='flex justify-between mt-[25px]'>
                                                        <h1>
                                                            Like to know the secrets of to know the winning Dynasty?
                                                        </h1>
                                                        <Button1 label="Schedule" />
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>
        </>
    )
}

export default ScheduleMaintenance