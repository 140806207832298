import React, { useState } from 'react'
import { Button1, Button3 } from '../../../CommonComponents/Button'
import { Loader } from '../../Elements/UpdateLoader'
import Pagination from '../../../CommonComponents/Pagination'
import DataTable from '../../../CommonComponents/DataTable'
import EmptyDataAlert from '../../../CommonComponents/EmptyDataAlert'

const EmailTable = () => {

    const [emailData, setEmailData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState(null);
    const [error, setError] = useState(true);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Campaign Name',
                accessor: ''
            },
            {
                Header: 'Receiver',
                accessor: ''
            },
            {
                Header: 'Sender',
                accessor: ''
            },
            {
                Header: 'Schedule time',
                accessor: ''
            },
            {
                Header: 'Status',
                accessor: ''
            },
            {
                Header: 'Category',
                accessor: ''
            },
            {
                Header: 'Action',
                accessor: '',
                Cell: ({ row }) => (
                    <div className="flex my-2">
                        <Button3
                            label="Edit"
                        // onClick={() => handleEdit(row.original._id)}
                        />
                    </div>
                )
            },
        ],
        []);

    const data = React.useMemo(() => emailData);

    return (
        <>
            <div className="flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>E-mail Scheduler</h1>
                    </div>
                    <div className='flex gap-5 mt-5'>
                        <div className='flex mt-5'>
                            <Button1 label="+Add More"
                            // onClick={handleAddUser}
                            />
                        </div>
                    </div>

                    <div className='flex'>
                        <input
                            type="text"
                            placeholder="Search by Name/Email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-bgclr3 rounded-md w-[890px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
                        />
                    </div>
                    {!error ? (
                        <div>
                            {fetchLoading ? (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="w-[975px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                    <Pagination
                                    // hasPrevPage={pagination.hasPrevPage}
                                    // hasNextPage={pagination.hasNextPage}
                                    // goToPrevPage={goToPrevPage}
                                    // goToNextPage={goToNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='mt-10'>
                            <EmptyDataAlert title="Add More Email Campaign." />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default EmailTable