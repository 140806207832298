import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const fetchBlogs = async (searchQuery) => {
    try {
        const response = await axiosInstance.get(`/blogs/list?search=${searchQuery}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    }
    catch (error) {
        console.error("Error Retrieving CMS User Data", error);
        throw error;
    }
}

// cdn link
export const imageCDN = async (file) => {
    console.log("file FormData -> ", file)
    if (!file) {
        alert('Please select a file first');
        return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axiosInstance.post('/blogs/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        console.log("Error Adding CMS User: ", error);
        showFailed("Failed...", `${error}`)
    }
};


// Add CMS User Data
export const addBlogData = async (formData, imgCdn) => {
    console.log("Add Blogs data -> ",formData);
    const updatedData = {
        title: formData.title,
        publish_date: formData.publishDate,
        description: formData.description,
        content: formData.pageContent,
        image: imgCdn,
        status: formData.status,
        author: formData.authorName,
        category: formData.category
    }
    try {
        const response = await axiosInstance.post(`/blogs`, updatedData);
        const data = response.data;
        console.log("CMS User response: ", data);

        if (response.status === 200) {
            return data;
        }
        else {
            showFailed('Failed...', `${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    }
    catch (error) {
        console.log("Error Adding CMS User : ", error);
        showFailed('Failed...', `${error.response.data.error[0]}`);
    }
}


// Get CMS User by ID
export const getBlogById = async (userId) => {
    try {
        const response = await axiosInstance.get(`/blogs/${userId}`);
        const data = response.data;
        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};


// Update CMS User

export const updateBlogData = async (formData, blogId, imgCdn) => {
    const updatedData = {
        title: formData.title,
        publish_date: formData.publishDate,
        description: formData.description,
        content: formData.pageContent,
        image: imgCdn,
        status: formData.status,
        author: formData.authorName,
        category: formData.category
    }
    try {
        const response = await axiosInstance.patch(`/blogs/${blogId}`, updatedData);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};
