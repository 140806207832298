import { createSlice } from "@reduxjs/toolkit";
import { addLoanData, updateLoanData } from "../Services/LoanMapping";

export const initialState = null;

export const reducer = (state, action) => {
    if (action.type === "LOAN") {
        return action.payload;
    }
    return state;
};

const loanSlice = createSlice({
    name: "loan",
    initialState,
    reducers: {
        addLoanDetails: (state, action) => {
            const { formData, position, collateral } = action.payload;
            addLoanData(formData, position, collateral);
        },
        updateLoanDetails: (state, action) => {
            const { formData, position, collateral, id } = action.payload;
            updateLoanData(formData, position, collateral, id);
        },
    }
});



export const { addLoanDetails, updateLoanDetails } = loanSlice.actions;
export default loanSlice.reducer;