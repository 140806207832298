import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FaSpinner } from 'react-icons/fa'
import Popup from 'reactjs-popup';
import Select from "react-select";
import { Country, State, City } from "country-state-city";

const AddIndividual = ({ isPreviewOpen, onClose, handleClose, handleSubmit, formData, isSubmitLoading }) => {

    const users = useSelector((state) => state.users);
    console.log("Add Users Data => ", users);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const indiaOption = Country.getAllCountries().find(country => country.name === "India");

    // Form validation
    const ValidationSchema = Yup.object({
        first_name: Yup.string().required('First Name is Required').min(3, 'Must be at least 3 characters'),
        last_name: Yup.string().required('Last Name is Required').min(3, 'Must be at least 3 characters'),
        gender: Yup.string().required('Gender is Required'),
        aadhaarNumber: Yup.string().required('Aadhaar Number is Required').matches(/^[0-9]{12}$/, 'Aadhaar Number is Invalid'),
        panNumber: Yup.string()
            .required('PAN Number is Required')
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'PAN Number is Invalid'),
        email: Yup.string().required('Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        phone: Yup.string().required('Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        whatsappNumber: Yup.string().required('WhatsApp Number is Required').matches(/^[0-9]{10}$/, 'WhatsApp Number is Invalid'),
        current_address: Yup.string().required('Current Address isRequired').min(5, 'Must be at least 5 characters'),
        state_name: Yup.string().required('State Name is Required'),
        city_name: Yup.string().required('City Name is Required'),
        permanent_address: Yup.string().required('Permanent Address is Required').min(5, 'Must be at least 5 characters'),
        alternate_number: Yup.string().required('Alternate Number is Required').matches(/^[0-9]{10}$/, 'Alternate Mobile Number is Invalid')
            .test('not-same-as-phone', 'Alternate number cannot be same as mobile number', function (value) {
                return value !== this.parent.phone;
            }),
        alternate_email: Yup.string().required('Alternate Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Alternate Email is Invalid')
            .test('not-same-as-email', 'Alternate email cannot be same as main email', function (value) {
                return value !== this.parent.email;
            })
    })

    const handleStateChange = (selectedState, setValues) => {
        setValues((prevValues) => ({
            ...prevValues,
            state_name: selectedState.name,
            city_name: ''    // Reset city name when state changes
        }));
        setSelectedState(selectedState);
    };

    const handleCityChange = (selectedCity, setValues) => {
        setValues((prevValues) => ({
            ...prevValues,
            city_name: selectedCity.name,
        }));
        setSelectedCity(selectedCity);
    };

    const handleSameAddress = (formikProps) => {
        formikProps.setFieldValue('permanent_address', formikProps.values.current_address);
    };


    return (
        <>

            <Popup open={isPreviewOpen} onClose={onClose} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div className="bg-gray-500 fixed inset-0 flex bg-opacity-50 min-h-screen justify-center items-center overflow-y-scroll" style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none' }}>

                        <div className="container p-8 rounded-md mx-auto mt-64">
                            <div className='mx-52'>
                                <Formik
                                    initialValues={formData}
                                    validationSchema={ValidationSchema}
                                    onSubmit={(values) => handleSubmit(values)}
                                >
                                    {(formikProps) => (
                                        <Form>
                                            <div className='bg-bg-form px-10 py-10 text-tbl-txt-clr'>
                                                <div className='flex justify-end'>
                                                    <button
                                                        type="submit"
                                                        onClick={() => {
                                                            handleClose();
                                                            close();
                                                        }}
                                                        className="text-2xl font-bold"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                <div className='flex justify-between'>
                                                    <div>
                                                        <h1 className='font-bold text-xl text-gray-500'>User Details</h1>
                                                    </div>

                                                    <div>
                                                        <span className='text-red-700 font-semibold text-md'>[* Indicates a required field]</span>
                                                    </div>
                                                </div>
                                                <div className="grid md:grid-cols-3 md:gap-5">
                                                    {/* First Name */}
                                                    <div className="mb-4 md:mt-5 mt-8">
                                                        <label htmlFor="fname" className="block text-sm font-medium ">
                                                            First Name<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="first_name"
                                                            name="first_name"
                                                            placeholder='First Name'
                                                            className="input-style rounded-md"
                                                        />
                                                        <ErrorMessage name="first_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    {/* Middle Name */}
                                                    <div className="mb-4 md:mt-5">
                                                        <label htmlFor="middle_name" className="block text-sm font-medium text-txtclr2">
                                                            Middle Name
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="middle_name"
                                                            name="middle_name"
                                                            placeholder='Middle Name'
                                                            className="input-style border border-gray-300 rounded-md"
                                                        />
                                                    </div>

                                                    {/* Last Name */}
                                                    <div className="mb-4 md:mt-5">
                                                        <label htmlFor="last_name" className="block text-sm font-medium text-txtclr2">
                                                            Last Name<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="last_name"
                                                            name="last_name"
                                                            placeholder='Last Name'
                                                            className="input-style border border-gray-300 rounded-md"
                                                        />
                                                        <ErrorMessage name="last_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>


                                                <div className="grid md:grid-cols-2 md:gap-5">
                                                    <div className='mb-4'>
                                                        <label htmlFor="AadhaarNumber" className="block text-sm font-medium text-txtclr2">
                                                            Aadhaar Number<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" maxLength={12} onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        }} placeholder='Aadhaar Number' id="aadhaarNumber" name="aadhaarNumber" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="aadhaarNumber" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    {/* Email */}
                                                    <div className="mb-4">
                                                        <label htmlFor="email" className="block text-sm font-medium text-txtclr2">
                                                            Email Address<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" placeholder='Email' id="email" name="email" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="email" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>


                                                <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                                                    {/* Mobile Number */}
                                                    <div className='mb-4'>
                                                        <label htmlFor="phone" className="block text-sm font-medium text-txtclr2">
                                                            Mobile Number<span className='text-red-800'>*</span>
                                                        </label>
                                                        <div className='flex'>
                                                            <Field type="text" maxLength={10} onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }} placeholder='Mobile Number' id="phone" name="phone" className="input-style border border-gray-300 rounded-md " />
                                                        </div>
                                                        <ErrorMessage name="phone" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className='mb-4'>
                                                        <label htmlFor="whatsappNumber" className="block text-sm font-medium text-txtclr2">
                                                            WhatsApp<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field type="text" maxLength={10} onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        }} placeholder='WhatsApp Number' id="whatsappNumber" name="whatsappNumber" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="whatsappNumber" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>

                                                <div className="grid md:grid-cols-5 md:gap-5 md:mt-2">
                                                    <div className='mb-4 md:col-span-3'>
                                                        <label htmlFor="panNumber" className="block text-sm font-medium text-txtclr2">
                                                            PAN Number<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            maxLength={10}
                                                            placeholder='Pan Number'
                                                            id="panNumber"
                                                            name="panNumber"
                                                            className="input-style border border-gray-300 rounded-md"
                                                            // Update state with the input value
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                formikProps.setFieldValue('panNumber', value.toUpperCase());
                                                            }}
                                                        />
                                                        <ErrorMessage name="panNumber" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className='mb-4 md:col-span-2 w-full'>
                                                        <label htmlFor="gender" className="block text-sm font-medium text-txtclr2">
                                                            Gender<span className='text-red-800'>*</span>
                                                        </label>
                                                        <select
                                                            id="gender"
                                                            name='gender'
                                                            className="input-style"
                                                            // value={formData.gender} // Make sure to bind the value
                                                            onChange={(e) => formikProps.setFieldValue('gender', e.target.value)} // Handle change
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                        <ErrorMessage name="gender" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>

                                                <div className="md:grid md:grid-cols-3 md:gap-8">
                                                    <div className="mt-2" disabled>
                                                        <label htmlFor="country_name" className="block text-sm font-medium text-txtclr2">
                                                            Country<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                                }),
                                                            }}
                                                            name='country_name'
                                                            id='country_name'
                                                            options={[{ ...indiaOption, isDisabled: true }]} // Disable India
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.name}
                                                            value={selectedCountry || indiaOption}
                                                            onChange={(item) => setSelectedCountry(item)}
                                                        />
                                                        <ErrorMessage name="country_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="state" className="block text-sm font-medium text-txtclr2">
                                                            State<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Select
                                                            name='state_name'
                                                            id='state_name'
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                                }),
                                                            }}
                                                            options={State?.getStatesOfCountry('IN')}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedState}
                                                            // onChange={(item) => {
                                                            //     setSelectedState(item);
                                                            // }}
                                                            onChange={(selectedState) => handleStateChange(selectedState, formikProps.setValues)}
                                                        />
                                                        <ErrorMessage name="state_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className="mt-2">
                                                        <label htmlFor="state" className="block text-sm font-medium text-txtclr2">
                                                            City<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Select
                                                            name='city_name'
                                                            id='city_name'
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                                }),
                                                            }}
                                                            options={City.getCitiesOfState(
                                                                selectedState?.countryCode,
                                                                selectedState?.isoCode
                                                            )}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedCity}
                                                            // onChange={(item) => {
                                                            //     setSelectedCity(item);
                                                            // }}
                                                            onChange={(selectedCity) => handleCityChange(selectedCity, formikProps.setValues)}
                                                        />
                                                        <ErrorMessage name="city_name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                </div>

                                                <div className="grid md:grid-cols-9 mt-3 md:mt-4 md:gap-5">
                                                    {/* Current Address */}
                                                    <div className="col-span-4 mb-4">
                                                        <label htmlFor="current_address" className="block text-sm font-medium text-txtclr2">
                                                            Current Address<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field as="textarea" placeholder='Current Address' id="current_address" name="current_address" className="input-style border border-gray-300 rounded-md" />
                                                        <ErrorMessage name="current_address" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className='flex col-span-1 my-2 justify-center'>
                                                        <input type="button" className='w-16 h-9 font-semibold rounded-md my-auto text-txt-lt-clr text-center bg-bgclr2' value='same' onClick={() => handleSameAddress(formikProps)} />
                                                    </div>
                                                    <div className="col-span-4 mb-4">
                                                        <label htmlFor="permanent_address" className="block text-sm font-medium text-txtclr2">
                                                            Permanent Address<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field as="textarea" placeholder='Permanent Address' id="permanent_address" name="permanent_address" className="input-style border border-gray-300 rounded-md" />
                                                        <ErrorMessage name="permanent_address" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>

                                                <div className="grid md:grid-cols-2 md:gap-5 md:mt-2">
                                                    {/* Alternate number */}
                                                    <div className="mb-4 md:mt-0">
                                                        <label htmlFor="alternate_number" className="block text-sm font-medium text-txtclr2">
                                                            Alternate Number
                                                        </label>
                                                        <Field type="text" maxLength={10}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                            placeholder='Alternate Number' id="alternate_number" name="alternate_number" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="alternate_number" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    {/* Alternate Email */}
                                                    <div className="mb-4 md:mt-0">
                                                        <label htmlFor="alternate_email" className="block text-sm font-medium text-txtclr2">
                                                            Alternate Email
                                                        </label>
                                                        <Field type="text" placeholder='Alternate Email' id="alternate_email" name="alternate_email" className="input-style border border-gray-300 rounded-md " />
                                                        <ErrorMessage name="alternate_email" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                </div>


                                                {/* Submit button */}
                                                <div className="flex mt-4 justify-center ">
                                                    <button
                                                        type="submit"
                                                        className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                    >
                                                        {isSubmitLoading ? <FaSpinner className="animate-spin text-3xl" /> : 'Submit'}
                                                    </button>
                                                </div>


                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                )}
            </Popup>

        </>
    )
}

export default AddIndividual