// store.js
import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './Reducer/UserReducer';
import EntityReducer from './Reducer/EntityReducer';
import UserManagementReducer from './Reducer/UserManagementReducer';
import RoleManagementReducer from './Reducer/RoleManagementReducer';
import BankReducer from './Reducer/bankReducer';
import BranchReducer from './Reducer/BranchReducer';
import BankIdReducer from './Reducer/BankIdReducer';
import BlogsManage from './Reducer/BlogsManage';
import LoanMappingReducer from './Reducer/LoanMappingReducer';

export const store = configureStore({
  reducer: {
      users: UserReducer,
      entity: EntityReducer,
      cmsusers: UserManagementReducer,
      cmsrole: RoleManagementReducer,
      bank: BankReducer,
      branch: BranchReducer,
      bankusers: BankIdReducer,
      blogs: BlogsManage,
      loan: LoanMappingReducer
  }
})
