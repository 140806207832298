import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const cmsLogin = async (formData) => {
    const updatedData = {
        email: formData.email
    }
    try {
      const response = await axiosInstance.post(`/auth/login`, updatedData);
      const data = response.data;
  
      if (response.status === 200) {
        return data;
      } else {
        showFailed('Failed...', `${response.status}`);
        throw new Error("Failed to fetch data. Status: " + response.status);
      }
    } catch (error) {
      showFailed('Failed...', `${error.response.data.error[0]}`);
      throw new Error('Error retrieving user data:', error);
    }
  };

  export const cmsLoginVerify = async (formData, otp) => {
    const updatedData = {
        email: formData.email,
        code: otp
    }
    try {
      const response = await axiosInstance.post(`/auth/verify`, updatedData);
      const data = response.data;
  
      if (response.status === 200) {
        return data;
      } else {
        throw new Error("Failed to fetch data. Status: " + response.status);
      }
    } catch (error) {
      showFailed('Failed...', `${error.response.data.error[0]}`);
      throw new Error('Error retrieving user data:', error);
    }
  };