import React, { useEffect, useState } from 'react'
import { Button1, Button3, Button4 } from '../../../CommonComponents/Button'
import RoleActions from './RoleActions'
import Loader from '../../../CommonComponents/Loader'
import { fetchRole } from '../../../../Services/RoleManagement'
import { useDispatch } from 'react-redux'
import { addRoleReducer, deleteRoleReducer, updateRoleReducer } from '../../../../Reducer/RoleManagementReducer'
import { showFailed } from '../../../CommonComponents/SweetAlert'
import Swal from 'sweetalert2'
import DataTable from '../../../CommonComponents/DataTable'
import { capitalizeFirstLetter } from '../../../CommonComponents/MiniFunction'

const RoleManagement = () => {

    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState();
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [operation, setOperation] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [userId, setUserId] = useState();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    const fetchData = async () => {
        setError(null);
        setFetchLoading(true);
        try {
            const data = await fetchRole(searchQuery);
            setRoles(data.docs);
            dispatch({ type: 'CMSROLE', payload: data });
            setIsLoading(false);
            setFetchLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(error.response.data.error[0])
            } else {
                console.error('Error fetching data:', error);
            }
            setIsLoading(false);
            setFetchLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [searchQuery]);

    const handleEdit = (_id) => {
        setOperation('update');
        setPreviewOpen(true);
        setUserId(_id)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Role',
                accessor: 'name',
                Cell: ({ value }) => capitalizeFirstLetter(value),
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="flex my-2">
                        <Button3
                            label="Edit"
                            onClick={() => handleEdit(row.original._id)}
                        /> | <Button4
                            label='Delete'
                            onClick={() => handleDelete(row.original._id)}
                        />
                    </div>
                )
            },
        ],
        []);

    const data = React.useMemo(() => roles);

    const handleAddRole = () => {
        setOperation('Add');
        setPreviewOpen(true);
    }

    const handleUpdate = (values) => {
        setIsLoadingUpdate(true);
        dispatch(updateRoleReducer({ formData: values, userId: userId }));
        handleClose();
        setFetchLoading(true);
        setTimeout(() => {
            fetchData();
            setIsLoadingUpdate(false);
        }, 500)
    }

    const handleDelete = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1D4ED8",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setFetchLoading(true);
                dispatch(deleteRoleReducer(_id));
                setTimeout(async () => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Role has been deleted.",
                        icon: "success"
                    });
                    await fetchData();
                }, 1000);
            }
        });
    };

    const handleSubmit = (values) => {
        try {
            dispatch(addRoleReducer(values));
            setPreviewOpen(false);
            setFetchLoading(true);
            setTimeout(async () => {
                await fetchData();
            }, 2000);
        } catch (error) {
            console.error('Error adding user:', error);
            showFailed("Failed to add user. Please try again later.");
        }
    };

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <>

            <div className="flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Role Management</h1>
                    </div>
                    <div className='flex gap-5 mt-5'>
                        <div className='flex mt-5'>
                            <Button1 label="+Add Role" onClick={handleAddRole} />
                        </div>
                    </div>

                    <div className='flex ml-9'>
                        <input
                            type="text"
                            placeholder="Search by Name/Email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-bgclr3 rounded-md w-[823px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
                        />
                    </div>
                    {!error ? (
                        <div>
                            {fetchLoading ? (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="w-[295px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex ml-80 mt-5'>
                            <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
                        </div>
                    )}
                </div>
            </div>

            {isPreviewOpen && (
                <RoleActions
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    // formData={formData}
                    // roleName={roleName}
                    isLoadingUpdate={isLoadingUpdate}
                    userId={userId}
                    operation={operation}
                // setFormData={setFormData}
                />
            )}

        </>
    )
}

export default RoleManagement