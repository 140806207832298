import React, { useEffect, useState } from 'react'
import { getPaymentDetails } from '../../../../Services/Payment'
import { showFailed } from '../../../CommonComponents/SweetAlert';
import { formatDate } from '../../../CommonComponents/MiniFunction';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import Loader from '../../../CommonComponents/Loader';

const PaymentDetails = ({ payment_id, onClose }) => {

    const [payData, setPayData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await getPaymentDetails(payment_id);
                setPayData(data.docs[0]);
                setLoading(false);
            } catch (error) {
                showFailed('Failed...', `${error}`);
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    return (
        <>
            <div className='w-[920px]'>
                {!loading ? (
                    <div>
                        <div className='flex justify-between'>
                            <div className='flex'>
                                <h1 className='text-3xl font-bold'>Payment Transaction Details</h1>
                            </div>
                            <button
                                className="flex items-center text-gray-500 border border-gray-400 px-3 py-1.5 rounded-md bg-bgclr9"
                                onClick={onClose}
                            >
                                <HiArrowNarrowLeft /> Back
                            </button>
                        </div>
                        <div className='bg-bgclr5 mt-5'>
                            <div className='p-5 border-b border-[#000000] border-opacity-30'>
                                <h1 className='text-[20px] font-[600]'>Payment Details</h1>
                            </div>
                            <div className='p-5 mt-5 flex gap-5'>
                                <div className='w-[50%]'>
                                    <h1 className='text-[18px] font-[600]'>Basic Details</h1>
                                    <div className='p-5 mt-3 h-[300px] rounded-lg border border-[#000000] border-opacity-30'>
                                        <table className='w-full text-[16px] font-[400]'>
                                            <tbody>
                                                <tr>
                                                    <td className='w-[180px]'>Name:</td>
                                                    <td>{`${payData?.first_name} ${payData?.middle_name} ${payData?.last_name}` || `${payData?.name}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>UCID:</td>
                                                    <td>{`${payData?.system_id}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{payData?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number:</td>
                                                    <td>{payData?.phone_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>WhatsApp:</td>
                                                    <td>{payData?.user?.personal?.whatsapp || ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>Country:</td>
                                                    <td>{payData?.address?.country}</td>
                                                </tr>
                                                <tr>
                                                    <td>State:</td>
                                                    <td>{payData?.address?.state}</td>
                                                </tr>
                                                <tr>
                                                    <td>City:</td>
                                                    <td>{payData?.address?.city}</td>
                                                </tr>
                                                <tr>
                                                    <td>Permanent Address:</td>
                                                    <td>{payData?.address?.permanent_address}</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Address:</td>
                                                    <td>{payData?.address?.current_address}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='w-[50%]'>
                                    <h1 className='text-[18px] font-[600]'>Transaction Details</h1>
                                    <div className='p-5 mt-3 h-[300px] rounded-lg border border-[#000000] border-opacity-30'>
                                        <table className='w-full text-[16px] font-[400]'>
                                            <tbody>
                                                <tr>
                                                    <td className='w-[180px]'>Invoice Number:</td>
                                                    <td>{payData?.invoice_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount:</td>
                                                    <td>Rs. {payData?.amount / 100}</td>
                                                </tr>
                                                <tr>
                                                    <td>System ID:</td>
                                                    <td>{payData?.system_id}</td>
                                                </tr>
                                                <tr>
                                                    <td>State:</td>
                                                    <td>{payData?.state}</td>
                                                </tr>
                                                <tr>
                                                    <td>Payment Method:</td>
                                                    <td>{payData?.type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Card Type:</td>
                                                    <td>{payData?.cardType || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>User Transaction Id:</td>
                                                    <td>{payData?.userTransactionId}</td>
                                                </tr>
                                                <tr>
                                                    <td>Response Code:</td>
                                                    <td>{payData?.responseCode}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date & Time:</td>
                                                    <td>{formatDate(payData?.created_at)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='-mt-20'>
                        <Loader />
                    </div>
                )}
            </div>

        </>
    )
}

export default PaymentDetails