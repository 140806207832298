import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const fetchCMSUser = async (searchQuery) => {
    try {
        const response = await axiosInstance.get(`/cms-roles?search=${searchQuery}`);
        const data = response.data;
        if (response.status === 200) {
            return data;
        } else {
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    }
    catch (error) {
        console.error("Error Retrieving CMS User Data", error);
        throw error; 
    }
}

// Add CMS User Data
export const addCmsUser = async (formData, permissions) => {
    console.log("Add CMS User data -> ",formData);
    const updatedData = {
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        gender: formData.gender,
        role: formData.role.toLowerCase(),
        permissions: permissions
    }
    try {
        const response = await axiosInstance.post(`/cms-roles`, updatedData);
        const data = response.data;
        console.log("CMS User response: ", data);

        if (response.status === 200) {
            return data;
        }
        else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    }
    catch (error) {
        console.log("Error Adding CMS User : ", error);
        showFailed('Failed...',`${error.response.data.error[0]}`);
    }
}


// Get CMS User by ID

export const getCMSUserById = async (userId) => {
    try {
        const response = await axiosInstance.get(`/cms-roles/${userId}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};


// Update CMS User

export const updateCMSUserData = async (formData, userId, permissions) => {
    const updatedData = {
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        gender: formData.gender,
        role: formData.role.toLowerCase(),
        permissions: permissions
    }
    try {
        const response = await axiosInstance.patch(`/cms-roles/${userId}`, updatedData);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};

// Delete CMS User

export const deleteCMSUserData = async (userId) => {
    try {
        const response = await axiosInstance.delete(`/cms-roles/${userId}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed...',`${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed...',`${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};