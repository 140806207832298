import { createSlice } from "@reduxjs/toolkit";
import { addBankData, updateBankUserData } from "../Services/BankCredential";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "BANKUSER") {
    return action.payload;
  }
  return state;
};

const cmsBankSlice = createSlice({
  name: "bankusers",
  initialState,
  reducers: {
    addBankUser: (state, action) => {
      addBankData(action.payload);
    },
    updateBankUser: (state, action) => {
      const { formData, bankId } = action.payload;
      updateBankUserData(formData, bankId);
    }
  }
});


export const { addBankUser, updateBankUser } = cmsBankSlice.actions;
export default cmsBankSlice.reducer;